import React, {useRef} from 'react'
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import { HashLink } from 'react-router-hash-link';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Regen = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
      <ScrollToTopOnMount/>
      <h1 ref={topRef} > ΑΝΑΓΓΕΝΗΤΙΚΗ ΙΑΤΡΙΚΗ <br/><br/> </h1>
      <div className="therapy-links">
          <div className="therapy"> <HashLink to="#regen-therapies" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Θεραπείες <br/> Aναγγενητικής <br/> Iατρικής</HashLink> </div>
      </div>
      <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
      <p>
        Η γήρανση όπως έχει αναφερθεί σχετίζεται τόσο με ενδογενείς όσο και με εξωγενείς παράγοντες. Η κλινική έκφραση της γήρανσης στο πρόσωπο είναι ποικίλη και αφορά τόσο στην επιπέδωση και
        απώλεια του όγκου στο κεντρικό πρόσωπο, όσο και στην χαλάρωση περιφερικά αυτού. Παράλληλα συνδυάζεται με επιφανειακές βλάβες στο δέρμα που περιγράφονται ως προς τη δομή, την ύπαρξη
        δυσχρωμικών και αγγειακών αλλοιώσεων και την εμφάνιση των ρυτίδων.
      </p>
      <p>
        Η ανάπλαση επομένως θα πρέπει να στοχεύει στην αντιστροφή και επανασύσταση των ιστών με βάση τον αιτιολογικό παράγοντα που διαμορφώνει τις αλλοιώσεις της γήρανσης, στα συγκεκριμένα σημεία
        του προσώπου. Συγκρίνετε για παράδειγμα μία φωτογραφία του προσώπου σας στην ηλικία των 20-30 ετών με μία φωτογραφία υπό την ίδια γωνία σε μια μεταγενέστερη ηλικία. Η διαφορά πολλές φορές
        είναι αποκαρδιωτική.
      </p>
      <p>
        Σήμερα η θεραπευτική προσέγγιση της πλαστικής χειρουργικής πέρα από την αρχή «αποκαθιστώ ιστό με παρόμοιο» στηρίζεται στην αποκατάσταση και αισθητική επανόρθωση των ιστών στο πρόσωπο σε
        τρία επίπεδα : δέρμα, αποκατάσταση όγκου και διόρθωση χαλάρωσης των ιστών. Παράλληλα η χρήση αυτόλογου υλικού δίνει εντυπωσιακά αποτελέσματα και σε βάθος χρόνου. Εδώ η χρήση των
        πολυδύναμων αρχέγονων κυττάρων (stem cells) και του αγγειακού διαστρωματικού κλάσματος (SVF) κερδίζει όλο και περισσότερο έδαφος στην καθημερινή πράξη.
      </p>
      <p>
        Στο κέντρο μας εφαρμόζουμε σύγχρονες θεραπείες αξιοποίησης του SVF για την αναγέννηση του προσώπου με τη χρήση nanofat, ενεργειακών θεραπειών (Laser, Hifu), Dermapen microneedling και
        μεσοθεραπείας μεμονωμένα ή σε συνδυασμό με επεμβατικές θεραπείες ανάλογα με τις ενδείξεις.
      </p>
      <br/><br/>
      <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
      <br/><br/>
      <h3 id='regen-therapies'>ΘΕΡΑΠΕΙΕΣ ΑΝΑΓΕΝΝΗΤΙΚΗΣ ΙΑΤΡΙΚΗΣ </h3>
      <p>
        Η πλαστική και επανορθωτική χειρουργική εφάρμοζε τη μεταφορά λίπους επί σχεδόν είκοσι χρόνια για την αποκατάσταση του όγκου σε συγκεκριμένες περιοχές του προσώπου ή του σώματος. Από την
        ανακάλυψη το 2001 των βλαστικών κυττάρων που προέρχονται από τον λιπώδη ιστό, πολλά έχουν αλλάξει στην καθημερινή πρακτική. Η δυνητική αξία και διαθεσιμότητα των βλαστικών κυττάρων του
        λιπώδους ιστού αναγνωρίστηκε από τη βιοκυτταρική αναγεννητική ιατρική τα τελευταία χρόνια. Ο λιπώδης ιστός θεωρείται πλέον ως μια δεξαμενή πολυδύναμων, αδιαφοροποίητων κυττάρων με
        αναγεννητικές και ομοιοστατικές ικανότητες, πέραν των αυξητικών ιδιοτήτων που έχει και που χρησιμοποιείται στην ειδικότητά μας.
      </p>
      <p>
        Αρχικά κάνουμε τη λιποαναρρόφηση του επιθυμητού όγκου από τη δότρια περιοχή που είναι κατά προτίμηση η κοιλιακή χώρα ή οι μηροί. Στη συνέχεια, το αναρροφημένο λίπος καθαρίζεται από το
        διάλυμα διήθησης μηχανικά και μετατρέπεται σε microfat που μπορεί να χρησιμοποιηθεί ως ενέσιμο υλικό πλήρωσης ή και αύξησης σε περιοχές με σημάδια γήρανσης του προσώπου ή άλλων περιοχών.
        Επιπλέον, μια πρόσθετη διαδικασία μπορεί να καθοδηγηθεί, με την προετοιμασία nanofat υλικού από το ήδη αναρροφημένο λίπος, που μπορεί να χρησιμοποιηθεί ως αναγεννητικός παράγοντας σε
        συγκεκριμένες ενδείξεις όπως:
      </p>
      <ol>
        <li><span>Τροφικές αλλαγές στο δέρμα που προκαλούνται από την ηλικία και την ηλιακή έκθεση</span>
            <br/>
            <ul>
                <li>Λεπτό χόριο (παρειές, κάτω βλέφαρα, χείλη)</li>
                <li>Ατροφία</li>
                <li>Επιφάνεια δέρματος εύθρυπτη</li>
                <li>Κηλίδες δυσχρωμίας</li>
            </ul>
        </li>
        <li>
            <span>Χρωστικές δερματικές παθήσεις</span>
            <br/>
            <ul>
                <li>Μαύροι κύκλοι περιοφθαλμικά που προκαλούνται από την διαφάνεια του δέρματος λόγω του λεπτού χορίου και την εναπόθεση μελανίνης (και τα δύο βελτιώνονται με την έγχυση
                     nanofat)</li>
                <li>Διάχυτες δυσχρωμίες στο πρόσωπο</li>
            </ul>
        </li>
        <li>
            <span>Ουλές και ατροφικές δερματικές παθήσεις</span>
            <br/>
            <ul>
                <li>Ουλές ακμής</li>
                <li>Υπερτροφικές ουλές</li>
                <li>Ατροφικές ουλές</li>
                <li>Ατροφία μετά ακτινοθεραπεία</li>
                <li>Ισχαιμική νέκρωση δερματικών κρημνών</li>
            </ul>
        </li>
      </ol>
      <p>
        Το nanofat υλικό αποτελείται από SVF (στρωματικό αγγειακό κλάσμα), το οποίο είναι ένας τεκμηριωμένος αναγεννητικός παράγοντας και μετά την παραγωγή του μπορεί να ενίεται με λεπτές
        κάνουλες ή βελόνες στο πρόσωπο. Ως SVF ορίζονται όλα τα ζωντανά κύτταρα που βρίσκονται στο υλικό λιποαναρρόφησης εκτός από τα ώριμα λιποκύτταρα. Το SVF του λιπώδους ιστού είναι μια
        πλούσια πηγή προ-λιποκυττάρων, μεσεγχυματικών βλαστικών κυττάρων (MSC), ενδοθηλιακών πρόδρομων κυττάρων, Τ κυττάρων, Β κυττάρων, μαστοκυττάρων και μακροφάγων του λιπώδους ιστού. Η
        παραγωγή του SVF μπορεί να προκληθεί είτε με ενζυμική διαδικασία μέσω της κολλαγενάσης ή μηχανικά. Η λεγόμενη κυτταρική υποβοηθούμενη λιπομεταφορά (CAL) με τη χρήση της κολλαγενάσης
        για το διαχωρισμό του SVF από το λιπώδη ιστό εξακολουθεί να είναι χρονοβόρα, δαπανηρή και σε πολλές χώρες αμφισβητούμενη, καθώς θεωρείται ένα «βιολογικό φάρμακο». Ο άλλος τρόπος είναι
        με τη μηχανική γαλακτωματοποίηση του δείγματος microfat με άσηπτο τρόπο. Υπάρχουν συγκριτικές μελέτες που αποδεικνύουν ότι το SVF που παράγεται με αυτή την τεχνική είναι συγκρίσιμο με την
        ενζυμική διαδικασία. Σε δείγματα που ελήφθησαν μέσω της προετοιμασίας μακρο, μικρο και νανο-λίπους, αναλύθηκαν το SVF και τα βλαστοκύτταρα. Τα βλαστοκύτταρα αναγνωρίστηκαν με την παρουσία
        αντιγόνου επιφάνειας CD34+. Στη συνέχεια αποδείχθηκε ότι τα κύτταρα SVF και τα βλαστοκύτταρα που προέρχονται από SVF ήταν παρόντα σε μεγάλες ποσότητες και στα τρία δείγματα μακρο, μικρό
        και νάνο λίπους.
      </p>
      <p>
        Η εφαρμογή για την αναγέννηση του του προσώπου μπορεί να γίνει στο επίπεδο του ιατρείου ή σε συνδυασμό με το facelift στο νοσοκομείο.
      </p>
      <br/><br/>
      <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
      <br/><br/>
    </div>
)};

export default Regen
