import React, {useRef} from 'react'
import A5a2 from './img/A5a2.jpg';
import A5a3 from './img/A5a3.png';
import A5a4 from './img/A5a4.jpg';
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import { HashLink } from 'react-router-hash-link';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)


const Innov = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
    <div className="sub_page">
      <ScrollToTopOnMount/>
      <h1 ref={topRef}> ΠΡΩΤΟΠΟΡΙΑΚΕΣ ΘΕΡΑΠΕΙΕΣ <br/><br/> </h1>
      <div className="therapy-links">
          <div className="therapy"> <HashLink to="#noninvasivecombo" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Συνδυασμός<br/>μη επεμβατικών<br/>θεραπειών</HashLink> </div>
          <div className="therapy_bg"> <HashLink to="#laserlipolysis" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Ενδοϊστική<br/>laser λιπόλυση</HashLink> </div>
          <div className="therapy"> <HashLink to="#lunchbreaktherapy" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Lunch-break<br/>θεραπείες<br/>προσώπου</HashLink> </div>
      </div>
      <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
      <h3 id='noninvasivecombo'>ΣΥΝΔΥΑΣΜΟΣ ΜΗ ΕΠΕΜΒΑΤΙΚΩΝ ΘΕΡΑΠΕΙΩΝ </h3>
      <p>
        Τα ορατά σημάδια της γήρανσης είναι ο συνδυασμός μεταξύ άλλων, της απώλειας λίπους και της ανακατανομής του, απορρόφησης του οστού και της αποδόμησης του κολλαγόνου/ελαστίνης.
        Αν λοιπόν όλα αυτά τα στοιχεία δεν αντιμετωπιστούν με τον κατάλληλο συνδυασμό, τότε τα αποτελέσματα μπορεί να είναι μακριά από τις προσδοκίες του καθένα.
      </p>
      <p>
        Τα νεώτερα από τις εξελίξεις  στον τομέα της αισθητικής χειρουργικής είναι μία αλματώδης  αύξηση των μη επεμβατικών και ελάχιστα επεμβατικών θεραπειών στην αντιμετώπιση της
        γήρανσης του δέρματος. Μεταξύ των πλέον διαδεδομένων είναι οι εφαρμογές της βουτυλινικής τοξίνης, των fillers και της χρήσης των Laser για αναγέννηση του δέρματος
        ( επεμβατικών και μη ).
      </p>
      <p>
        Οι ενδιαφερόμενοι αναζητούν λύσεις με τον λιγότερο κίνδυνο, τον ελάχιστο δυνατό χρόνο ανάρρωσης και την καλύτερη προσέγγιση στην αντιμετώπιση της γήρανσης. Επιπλέον εφαρμόζονται
        θεραπείες σε νεότερες ηλικίες με αποτέλεσμα οι μη επεμβατικές θεραπείες να είναι η ενδεδειγμένη λύση και με προβλέψιμα αποτελέσματα.
      </p>
      <p>
        Οι διαφορετικές παράμετροι που αφορούν τη γήρανση του δέρματος προϋποθέτουν και πιο σύνθετη προσέγγιση. Ένα συνδυασμό δηλαδή θεραπειών, όπου η ακολουθία αυτών των θεραπειών να
        μην επηρεάζει ή να αναιρεί την άλλη. Πότε για παράδειγμα εφαρμόζουμε τα Laser όταν επιλέγουμε θεραπείες και με fillers, είτε αυτά έχουν τη μορφή του υαλουρονικού, του ενέσιμου
        poly-L-lactic acid, ή του λίπους. Πώς μπορεί η αυτόλογη μεσοθεραπεία ή μεσοθεραπεία με άλλα ενέσιμα να συνδυαστεί και πότε με άλλες θεραπείες κλπ. Επιπρόσθετα ο συνδυασμός των
        προτεινόμενων θεραπειών με την Υπερβαρική Αναπλαστική Οξυγονοθεραπεία, που εφαρμόζουμε στο Κέντρο μας σε συνεργασία με ειδικούς ιατρούς, δίνει συνολικά εντυπωσιακά και μακρόχρονα
        αποτελέσματα, διεγείροντας φυσικούς μηχανισμούς και ελαχιστοποιώντας τη χρήση βιοσυνθετικών υλικών, όπου μόνο υπάρχει απόλυτη ένδειξη. Η σωστή ιεράρχηση λοιπόν των προτεινόμενων
        θεραπειών και η διαμόρφωση ενός «πλάνου» θεραπείας, είναι αυτό που αποκαλούμε το «Σχέδιο» της θεραπείας.
      </p>
      <br/><br/>
      <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
      <br/><br/>
      <h3 id='laserlipolysis'>ΕΝΔΟΪΣΤΙΚΗ LASER ΛΙΠΟΛΥΣΗ <br/> &nbsp;&nbsp;&nbsp; Ή ΛΙΠΟΑΝΑΡΡΟΦΗΣΗ με τη χρήση Laser και Υπερβαρικού Οξυγόνου</h3>
      <p>
        Η λιποαναρρόφηση παραμένει μία από τις πιο δημοφιλείς επεμβάσεις παγκοσμίως. Η ευρεία εφαρμογή της ανέπτυξε διάφορες τεχνικές τα τελευταία χρόνια,
        πέραν της παραδοσιακής, για την αφαίρεση του τοπικού λίπους και την ανάπλαση του περιγράμματος του σώματος. Τέτοιες είναι η λιποαναρρόφηση μέσω
        υπερήχων, η μηχανικά υποβοηθούμενη και η χρήση των Laser.
      </p>
      <div className="sub_flex">
        <div className="flexitem_a">
          <img src={A5a2} alt="laser" className="img-width-small" />
        </div>
        <div className="flexitem_text_w_picture_wide">
            H εφαρμογή των διοδικών Laser στην λιπόλυση / λιποαναρρόφηση έφερε πραγματικά εντυπωσιακά αποτελέσματα μέσω της καταστροφής του λίπους,
            του περιορισμού της αιμορραγίας κατά την επέμβαση, του ελάχιστου χρόνου ανάρρωσης  και της ρίκνωσης του δέρματος. Έχει άριστα αποτελέσματα
            σε τοπικό λίπος σε περιοχές όπως το πηγούνι, τα γόνατα, την κοιλιά, την έξω επιφάνεια των γλουτών, τη μέση, την έσω και έξω επιφάνεια των
            μηρών και τα γόνατα καθώς και σε ορισμένες μορφές γυναικομαστίας.
        </div>
      </div>
      <div className="sub_flex">
        <div className="flexitem_text_w_picture_wide">
            Γίνεται με τοπική έγχυση αναισθητικού διαλύματος και εκπομπή δέσμης Laser μέσω οπτικής ίνας που εισάγεται στους ιστούς με τη βοήθεια μιας
            ειδικής κάνουλας και καταστροφή των λιποκυττάρων. Ανάλογα με το πάχος του λίπους μπορεί να γίνει σε διαφορετικά επίπεδα, με τελευταίο αυτό,
            κοντά και κάτω από το δέρμα ώστε να επιτευχθεί η ρίκνωσή του . Μπορεί να ολοκληρωθεί με αναρρόφηση του κατεστραμμένου λίπους ή αν είναι
            πολύ μικρή επιφάνεια με παροχέτευση με μασάζ. Στη συνέχεια η περιοχή επιδένεται με κατάλληλους επιδέσμους και ο ασθενής φεύγει περιπατητικός.
            Η επιστροφή στην εργασία του μπορεί να γίνει μια μέρα μετά.
        </div>
        <div className="flexitem_a">
          <img src={A5a3} alt="laser" className="img-width-small" />
        </div>
      </div>
      <p>
        Οι κανονικές δραστηριότητες συστήνεται να επανέρχονται σε φυσιολογικό ρυθμό μετά τη βδομάδα ενώ η άθληση μετά την παρέλευση του μήνα.
      </p>
      <p>
        Ο μηχανισμός έχει αποδειχθεί ότι δεν είναι φωτομηχανικός αλλά συνάρτηση της θερμοκρασίας που αναπτύσσεται και της συνολικής ενέργειας που
        διοχετεύεται σε μια περιοχή του σώματος.
      </p>
      <p>
        Πολλές φορές η ρίκνωση του δέρματος είναι ορατή με το πέρας της χειρουργικής διαδικασίας.
      </p>
      <div className="sub_flex">
        <div className="flexitem_a">
          <img src={A5a4} alt="laser" className="img-width-small" />
        </div>
        <div className="flexitem_text_w_picture_long">
            Στο κέντρο μας, ο συνδυασμός της λιποαναρρόφησης με θεραπεία Υπερβαρικού Οξυγόνου αμέσως μετά την επέμβαση, δίνει ακόμη πιο εντυπωσιακά
            αποτελέσματα αφού περιορίζεται ακόμη περισσότερο το οίδημα των ιστών και ελαττώνεται η περίοδος αποθεραπείας σημαντικά.
        </div>
      </div>
      <br/><br/>
      <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
      <br/><br/>
      <h3 id='lunchbreaktherapy'>LUNCH BREAK ΘΕΡΑΠΕΙΕΣ ΠΡΟΣΩΠΟΥ</h3>
      <p>
        Πρώτον, οι περισσότεροι θα ήθελαν μια πιο ελκυστική και ανανεωμένη εμφάνιση με λύσεις που θα είναι λιγότερο «επώδυνες» και «τραυματικές». Και δεύτερον, οι θεραπείες με τον γενικό χαρακτηρισμό
        «lunch break», αφού η συντομία της εφαρμογής τους μπορεί να διαρκέσει όσο το διάλειμμα μας για ένα μεσημεριανό γεύμα, σε καμία περίπτωση δεν δύνανται να υποκαταστήσουν τις παραδοσιακές επεμβατικές
        μεθόδους της πλαστικής χειρουργικής, ως προς τα αποτελέσματα και τη διάρκειά τους. Δίνουν όμως την δυνατότητα σε κάποιον ή κάποια να βελτιώσει την εμφάνιση και την υγεία ιδιαίτερα του προσώπου του,
        χωρίς τους περιορισμούς της χρονοβόρας ανάρρωσης και αποθεραπείας, της παροδικής αναστολής της κοινωνικής δραστηριότητας και της μονιμότητας των αποτελεσμάτων μιας χειρουργικής επέμβασης.
      </p>
      <p>
        Όλες λοιπόν αυτές οι θεραπείες κατατάσσονται στις μη χειρουργικές αλλά ταυτόχρονα επεμβατικές μεθόδους που έχουν να κάνουν με την υφή και ποιότητα του δέρματος του προσώπου, τη σύσταση του κολλαγόνου
        και την δυναμική των υποκείμενων ιστών ( λίπους, μυών κ.α ).  Στις πιο ήπιες από αυτές, συγκαταλέγονται τα peelings διάφορης πυκνότητας με γλυκολικό ή κοζικό οξύ, που έχουν στόχο την απομάκρυνση των
        νεκρών κυττάρων της επιδερμίδας, την ενεργοποίηση της βασικής στιβάδας της επιδερμίδας και την παραγωγή νέων κυττάρων, προσδίδοντας έτσι στο δέρμα του προσώπου λάμψη και φρεσκάδα. Λόγω της ηπιότητας
        της δράσης τους, η επανάληψή τους ανά 7-10 μέρες για 5-6 φορές κρίνεται σκόπιμη για όσο το δυνατόν καλύτερα αποτελέσματα.
      </p>
      <p>
        Οι ενέσεις Βουτυλινικής τοξίνης τύπου Α, ακινητοποιούν τμήματα μυών, προσδίδοντας μια πιο ήπια και ξεκούραστη εμφάνιση στο πρόσωπο. Η δράση τους είναι παροδική από 4-6 μήνες, άρα χρειάζεται και η ανά
        εξάμηνο τουλάχιστο επανάληψή τους.
      </p>
      <p>
        Η χρήση ενέσιμων εμφυτευμάτων ( υαλουρονικού οξέος,) βοηθάει στην επίλυση προβλημάτων ρυτίδων στις ρινοχειλικές περιοχές, περιοφθαλμικά και στο μεσόφρυο, στην αντιμετώπιση ουλών αλλά και στην διαμόρφωση
        των χειλιών. Δίνουν άμεσα αποτελέσματα και η διάρκειά τους κυμαίνεται από 6 ως 12 μήνες. Μπορεί να εφαρμοστούν και σε συνδυασμό με ενέσεις βουτυλικής τοξίνης.
      </p>
      <p>
        Η τοποθέτηση νημάτων ( PDO και COG ), η microneedling μεσοθεραπεία, η αναίμακτη μεσοθεραπεία με τη βοήθεια υπερυθρης ακτινοβολίας αλλά και η αυτόλογη μεσοθεραπεία ( PRP και PRF ) συνθέτουν ένα ευρύτατο
        φάσμα για μία σύγχρονη, ελάχιστα επεμβατική και ιδιαίτερα αποτελεσματική θεραπεία για την απόδοση ενός λαμπερού, σφριγηλού και υγιούς δέρματος στο πρόσωπο, λαιμό και ντεκολτέ.
      </p>
      <p>
        Για την βελτίωση της υφής του δέρματος, την αντιμετώπιση δυσχρωμιών και αγγειακών βλαβών (τηλεαγγειεκτασίες προσώπου)  και την διέγερση και σύσφιγξη  του κολλαγόνου οι εφαρμογές ειδικών Laser η της
        τεχνολογίας του παλλόμενου φωτός ή των ραδιοκυμάτων επιλεκτικά, αποτελούν ένα επιπλέον όπλο στα χέρια του πλαστικού χειρουργού. Σήμερα δε με την εξέλιξη των Laser κλασματικής (fractional) δερμοαπόξεσης
        διαφορετικού μήκους κύματος, συμβαίνει άμεση και χωρίς επιπλοκές επούλωση, καθιστώντας τα έτσι ό,τι πιο σύγχρονο και αποτελεσματικό υπάρχει στην αντιμετώπιση της φωτογήρανσης του δέρματος του προσώπου.
        Ανάλογα με την επιλογή της τεχνολογίας και των επιμέρους παραμέτρων της, απαιτούνται από μία έως 4 συνεδρίες με μεσοδιαστήματα 3-4 εβδομάδων.
      </p>
      <p>
        Ο μοναδικός στο Κέντρο μας συνδυασμός που γίνεται, πολλών από τις παραπάνω θεραπείες με την Υπερβαρική Οξυγονοθεραπεία, μπορεί να δώσει πολύ εντυπωσιακά και άμεσα αποτελέσματα στην εμφάνισή σας. Ακόμη
        κι αν θέλετε να κάνετε μία εντυπωσιακή εμφάνιση σε ένα γεγονός το ίδιο βράδυ, μπορεί να σχεδιαστεί κάτι ιδιαίτερο και άμεσο για τις ανάγκες σας.
      </p>
      <p>
        Φυσικά δεν πρέπει να ξεχνάμε ότι σε όλες τις παραπάνω εφαρμογές είναι απαραίτητη η μετέπειτα σωστή και κατάλληλη ενυδάτωση και η αντι ηλιακή προστασία ώστε να έχουμε άμεση και φυσιολογική επούλωση.
      </p>
      <br/><br/>
      <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
      <br/><br/>
    </div>
    )
}


export default Innov
