import React, { Component, useRef} from 'react';
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import A1a1 from './img/A1a1.jpg'
import A1a2 from './img/A1a2.jpg'

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Facenon = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
        <ScrollToTopOnMount/>
        <h1 ref={topRef}> ΠΡΟΣΩΠΟ <br/> Μη Επεμβατικες θεραπειες </h1>
        <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
        <h3>ΑΝΑΙΜΑΚΤΗ ΜΕΣΟΘΕΡΑΠΕΙΑ <br/> με την Κάψουλα της Μακράς Υπέρυθρης ακτινοβολίας</h3>
        <h4> 1.	Σχετικά με την υπέρυθρη ακτινοβολία </h4>
        <p>
          Το ορατό φάσμα του λευκού φωτός, ότι δηλαδή αντιλαμβάνεται το μάτι μας, βρίσκεται  μεταξύ 400 nm του ιώδους και 700 nm του ερυθρού.
          Οτιδήποτε είναι πέραν των ορίων αυτών ανήκει στη μη ορατή ακτινοβολία και αν είναι πέραν του ιώδους αφορά την υπεριώδη ακτινοβολία
          και πέραν του ερυθρού στην υπέρυθρη ακτινοβολία. Το τμήμα της υπερυθρης από 3 έως 106  ανήκει στη μακρά υπέρυθρη ακτινοβολία
          ( Far Infrared Radiation ) και προκαλεί αύξηση της θερμοκρασίας των στερεών και υγρών σωμάτων.
        </p>
        <h4>2. Σχετικά με την θεραπεία</h4>
        <p>
          Το πρόσωπο παραμένει μέσα σε μία κάψουλα που εκπέμπει υπέρυθρη ακτινοβολία για 30 λεπτά που προκαλεί
        </p>
        <ul>
          <li> Τοπική αγγειοδιαστολή και αύξηση της αιματικής ροής στο δέρμα</li>
          <li> Αύξηση του τοπικού μεταβολικού ρυθμού του σώματος και επιτάχυνση της διαδικασίας επούλωσης</li>
          <li> Τοπική εφίδρωση</li>
          <li> Διέγερση των ινοβλαστών και μακροπρόθεσμα αύξηση του κολλαγόνου και της ελαστίνης</li>
        </ul>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A1a1} alt="IR capsule" className="IRcapsule" />
          </div>
          <div className="flexitem_b">
            <img src={A1a2} alt="O2 chamber" className="O2chamber" />
          </div>
        </div>
        <p>
          Μετά το πέρας της διαδικασίας, η αγγειοδιαστολή, το άνοιγμα των πόρων και η τοπική αύξηση της αιματικής ροής  επιταχύνουν την
          απορρόφηση αποστειρωμένων σκευασμάτων μεσοθεραπείας που επαλείφονται στο πρόσωπο και που ποικίλλουν ανάλογα με το πρόβλημα.
        </p>
        <p>
          Η εφαρμογή αυτή συνδυάζεται με μία ή δύο συνεδρίες στο Θάλαμο Υπερβαρικού Οξυγόνου για ακόμη πιο εντυπωσιακά και γρήγορα αποτελέσματα.
        </p>
        <h4>3.	Πού εφαρμόζουμε  τη θεραπεία αυτή</h4>
        <p>
          Γενικά σε γυναίκες μέσης ηλικίας ( 35-45 ) με ήπιες αλλοιώσεις στο δέρμα του προσώπου ή μετά την περίοδο της εγκυμοσύνης και του θηλασμού.
          <br/> Ειδικότερα, εφαρμόζεται σε
        </p>
        <ul>
          <li>Ήπια φωτογήρανση</li>
          <li>Λεπτές ρυτίδες, δυσχρωμίες, λίγες  τηλεαγγειεκτασίες και αφυδάτωση στο δέρμα</li>
          <li>Νεότερες ηλικίες</li>
          <li>Άτομα που δεν επιθυμούν οποιαδήποτε επεμβατική προσέγγιση</li>
          <li>Αναστροφή των βλαβών του καλοκαιριού</li>
          <li>Τouch up σε events</li>
          <li>Διατήρηση της νεανικότητας</li>
          <li>Συντήρηση αποτελεσμάτων άλλων μεθόδων</li>
        </ul>
        <h4>4.	Τι επιτυγχάνουμε με τη θεραπεία</h4>
        <p>
          Με τη θεραπεία αυτή και εντελώς <b>ανώδυνα</b> επιτυγχάνουμε <b>γρήγορη</b> και <b>εντυπωσιακή</b> αναστροφή των Βλαβών στο δέρμα, επαναφέρεται η λάμψη,
          η ενυδάτωση και η σφρυγηλότητα του, τονίζεται η ελαστικότητά του και η φρεσκάδα του. Το αποτέλεσμα χαρακτηρίζεται από τη διάρκεια
          του ενώ για τη μέγιστη αποτελεσματικότητα, ο αριθμός των συνεδριών κυμαίνεται από 4 - 8 θεραπείες συνολικά ανά εβδομάδα περίπου και
          ανάλογα με το πρόβλημα που παρουσιάζει ο καθένας. Το πιο σημαντικό όμως είναι ότι το αποτέλεσμα αυτό κερδίζεται εντελώς αναίμακτα
          και ανώδυνα.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
      </div>
)};

export default Facenon
