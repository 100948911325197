import React, { useRef} from 'react';
import A4a1 from './img/A4a1-breast.jpg';
// import A4b1 from './img/need-pic.png';
// import A4c1 from './img/need-pic.png';
import A4e1 from './img/A4e1-belly.jpg';
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import { HashLink } from 'react-router-hash-link';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}

const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Torso = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
      <ScrollToTopOnMount/>
        <h1 ref={topRef} > ΚΟΡΜΟΣ <br/><br/> </h1>
        <div className="therapy-links">
            <div className="therapy_bg"> <HashLink to="#augmentation" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Αύξηση<br/>μαστού</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#reduction" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Μειωτική<br/>μαστού</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#lift" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Ανόρθωση<br/>μαστού</HashLink> </div>
            <div className="therapy"> <HashLink to="#more" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Άλλες επεμβάσεις<br/>στο μαστό</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#abdominoplasty" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Κοιλιοπλαστική<br/></HashLink> </div>
        </div>
        <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
        <h3 id='augmentation'>ΑΥΞΗΣΗ ΜΑΣΤΟΥ</h3>
        <p>
          Ο μαστός έχει χαρακτηριστεί διαχρονικά ως το «απόλυτο σύμβολο» μητρότητας, θηλυκότητας και ερωτικής έλξης. Έχει τύχει της προσοχής και του
          ενδιαφέροντος ανάμεσα σε ζωγράφους, γλύπτες, ποιητές, επιστήμονες και η αρθρογραφία που αφορά το γυναικείο στήθος είναι τεράστια και
          πολύ-συλλεκτική. Στην πλαστική χειρουργική, η αύξηση του στήθους , συγκεντρώνει το ενδιαφέρον τόσο ανάμεσα στον γυναικείο πληθυσμό όσο και
          στον κόσμο των πλαστικών χειρουργών και αποτελεί μία από τις συχνότερες επεμβάσεις που γίνονται σε παγκόσμια κλίμακα.
        </p>
        <h4> 1.	Σχετικά με το μαστό </h4>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A4a1} alt="female sideface" className="img-width-small" />
          </div>
          <div className="flexitem_text_w_picture">
              Η διαμόρφωση του γυναικείου μαστού ολοκληρώνεται στην πλειοψηφία των περιπτώσεων στην ηλικία των 17-18 ετών. Το τελικό του σχήμα και μέγεθος,
              η συμμετρία ή όχι στη διαμόρφωση και των δύο μαστών αλλά και μια σειρά άλλων παθολογικών καταστάσεων όπως η αμαστία, η απλασία, οι σωληνωτοί
              μαστοί  καθορίζουν και το είδος της επέμβασης που θα πρέπει να γίνει.
          </div>
        </div>
        <p>
          Στόχος οποιαδήποτε επέμβασης πλαστικής χειρουργικής στο μαστό είναι να  αποδώσει ένα μαστό που να ακολουθεί τις αναλογίες του σώματος κάθε
          γυναίκας, να σέβεται τις αρχές της συμμετρίας αλλά και το ίδιο το «όργανο» που πέρα από εμφανισιακούς και ενδυματολογικούς λόγους έχει και
          τέτοια λειτουργική σημασία που είναι ταυτισμένη με το ρόλο της γυναίκας στην ανθρώπινη ιστορία.
        </p>
        <p>
          Ένας «φυσιολογικός» μαστός έχει ένα όγκο μεταξύ 300-500 cc, έχει θέση στο θώρακα ανάμεσα στο στέρνο και την πρόσθια γραμμή της μασχάλης και
          μεταξύ 2ης -7ης πλευράς και η θηλή  βρίσκεται στο πιο προεξέχον σημείο του μαστού στο ύψος περίπου του 4ου μεσοπλεύριου διαστήματος.
        </p>
        <h4>2. Σχετικά με την επέμβαση</h4>
        <p>
          Η επέμβαση γίνεται συνήθως με γενική αναισθησία σε καλά οργανωμένα κέντρα όπου εξασφαλίζονται άριστες συνθήκες αντισηψίας. Προεγχειρητικά θα
          πρέπει να έχει γίνει διεξοδική συζήτηση σχετικά με:
        </p>
        <ul>
          <li> Το σημείο από όπου θα τοποθετηθεί το ένθεμα (τομή),</li>
          <li> το ανατομικό επίπεδο όπου θα τοποθετηθεί (βάθος),</li>
          <li> τι είδους ένθεμα θα επιλεγεί (υλικό),</li>
          <li> τι τύπου και όγκου ένθεμα θα χρησιμοποιηθεί (σχήμα)</li>
        </ul>
        <p>
          <b>Ως προς την επιλογή της τομής:</b>
        </p>
        <ul>
          <li> Oι συνήθεις θέσεις της τομής είναι γύρω από τη θηλή, η υπομάστια  πτυχή και η μασχαλιαία πτυχή</li>
          <li>
            Η τομή γύρω από την θηλή έχει το πλεονέκτημα ότι είναι λιγότερο εμφανής, αλλά διατέμνει τον φυσιολογικό αδένα και περιορίζεται η εφαρμογή της
            σε χρήση μικρών ενθεμάτων και ικανοποιητικού διαμετρήματος της θηλαίας άλω για να μπορέσει να εισέλθει το ένθεμα.
          </li>
          <li> Η τομή της υπομαστίου δεν έχει το προαναφερόμενο μειονέκτημα, δεν παρεμβαίνει στο παρέγχυμα του αδένα και γι αυτό θεωρείται ίσως η πιο σωστή, ιατρική λύση.</li>
          <li>
             Η τομή από τη μασχάλη είναι απομακρυσμένη σχετικά από τον μαστό, είναι τεχνικά πιο δύσκολη και η πρόσβαση σε όλα τα σημεία της δημιουργούμενης θήκης μπορεί να
             αποδειχθεί προβληματική ενώ και η ουλή που μπορεί να αφήσει να είναι πιο εμφανής.
          </li>
          <li>
             Αναφέρεται και η πρόσβαση από τον ομφαλό που όμως δεν τυγχάνει ευρείας εφαρμογής καθόσον μπορούν να τοποθετηθούν μόνο ενθέματα φυσιολογικού ορρού και ενέχουν
             τον κίνδυνο μη διατήρησης του όγκου τους σε βάθος χρόνου.
          </li>
        </ul>
        <p>
          <b>Ως προς την επιλογή της θέσης:</b>
        </p>
        <p>
          Τα ενθέματα μπορούν να τοποθετηθούν
        </p>
        <ul>
          <li> Κάτω από τον μαζικό αδένα</li>
          <li> Κάτω από την μυική περιτονια ( subfascial )</li>
          <li> Kάτω από το θωρακικό μυ ( submuscular )</li>
          <li> Συνδυασμένα από τις παραπάνω θέσεις ( dual plane )</li>
        </ul>
        <p>
          Η επιλογή της θέσης εξαρτάται από το σωματότυπο της γυναίκας, πόσο λεπτό είναι το δέρμα κι αν οι υπάρχοντες μαστοί είναι μικροί καθώς και από τις ιδιαίτερες συνήθειες που έχει.
          Έτσι σε άτομα που αθλούνται, η θέση κάτω από τον μυ ενέχει τον κίνδυνο της μετακίνησης του ενθέματος προς τα πάνω ιδιαίτερα όταν υποβληθούν σε πρόωρη άσκηση
        </p>
        <p>
          <b>Ως προς τα ενθέματα</b>
        </p>
        <p>
          Τα ενθέματα που θα χρησιμοποιηθούν, μπορεί να είναι είτε από συνεκτική γέλη σιλικόνης ( cohessive ή απλά ), λείας ή τραχείας επιφανείας, ή από φυσιολογικό ορρό, ενώ ως προς το
          σχήμα τους διακρίνονται σε ανατομικά και μη ( στρογγυλά ). Επίσης, ανάλογα με το βαθμό της προβολής τους, διακρίνονται σε : χαμηλής προβολής ( low profile ), μέτριας προβολής,
          υψηλής προβολής ( high profile ) και πολύ υψηλής προβολής (ultra high profile). Η επιλογή του κατάλληλου ενθέματος από άποψη τύπου, σχήματος και όγκου, εξαρτάται τόσο από τα
          σωματομετρικά στοιχεία όσο και από τις προτιμήσεις της γυναίκας. Ο πλαστικός χειρουργός οφείλει εδώ να αναδείξει τα πλεονεκτήματα της επιλογής του καθώς και τους περιορισμούς
          που μπορεί να υπάρχουν, εξατομικευμένα στο κάθε άτομο.
        </p>
        <p>
          Σήμερα τα σύγχρονα ενθέματα συνεκτικής γέλης σιλικόνης θεωρούνται απολύτως ασφαλή και οι παραγωγές εταιρείες δίνουν γι αυτά, εγγύηση εφ’ όρου ζωής. Παρόλα αυτά, ο ασθενής
          οφείλει να έχει γνώση σχετικά με τα ενθέματα που θα χρησιμοποιηθούν, την προέλευσή και την πιστοποίησή τους καθώς και της τεχνικής που θα επιλεγεί τελικά από τον ιατρό.
        </p>
        <p>
          <b>ΣΗΜΑΝΤΙΚΑ ΖΗΤΗΜΑΤΑ ΠΟΥ ΑΦΟΡΟΥΝ ΤΗΝ ΑΥΞΗΤΙΚΗ ΜΑΣΤΟΥ ΜΕ ΕΝΘΕΜΑΤΑ</b>
        </p>
        <p>
          Η γυναίκα που έχει υποβληθεί σε αύξηση του μαστού με ενθέματα οφείλει να ελέγχεται συστηματικά με περιοδικούς ελέγχους που καθορίζονται από τον ιατρό της.
        </p>
        <p>
          Η εξέταση του μαστού που φέρει ενθέματα, μπορεί να γίνει κλινικά και ακτινολογικά, χωρίς κανένα πρόβλημα πλέον, μιας και οι απεικονιστικές μέθοδοι και τρόποι λήψεων έχουν
          εξελιχθεί και το ένθεμα βρίσκεται κάτω από το μαστό και όχι μέσα σε αυτόν. Υποχρέωση της γυναίκας είναι να αναφέρει ότι φέρει ενθέματα μαστού πριν από όποια εξέταση.
        </p>
        <p>
          <b>Η συσχέτιση των ενθεμάτων μαστού, με το συνήθη καρκίνο του μαστού ή νόσους του κολλαγόνου, κατηγορηματικά δεν υφίσταται και αυτό έχει αποδειχθεί επιστημονικά.</b> Ενώ η συχνότητα
          εμφάνισης καρκίνου του μαστού είναι η ίδια ανάμεσα στην κατηγορία των γυναικών που φέρουν ενθέματα μαστού σε σχέση με το γενικό γυναικείο πληθυσμό, όπως αποδείχτηκε από μεγάλες
          πολυκεντρικές επιστημονικές μελέτες, η επιβίωσή τους είναι σημαντικά καλύτερη. Κι αυτό λόγω της έγκαιρης διάγνωσης του καρκίνου σε πρώιμα στάδια εξαιτίας της ευαισθητοποίησης
          αυτών των γυναικών και της πιο συστηματικής ιατρικής παρακολούθησης σε σχέση με το γενικό πληθυσμό.
        </p>
        <p>
          Πρόσφατα έχει περιγραφεί μία νέα κλινική οντότητα, που αφορά σε περιπτώσεις αύξησης του μαστού με ενθέματα και είναι το αναπλαστικό κυτταρικό λέμφωμα του μαστού ( ΒΙΑ-LCA ), που μπορεί
          να παρατηρηθεί ακόμη και χρόνια μετά την αύξηση αυτή. Η συχνότητά του είναι πολύ σπάνια ( 1:30000 ), παρόλα αυτά η έρευνα σχετικά με την εμφάνιση και τα αίτια της συνεχίζεται και η
          αντιμετώπιση του εφόσον διαπιστωθεί,  είναι χειρουργική με αφαίρεση των ενθεμάτων και εφαρμογή συστηματικής θεραπείας.
        </p>
        <p>
          Γυναίκες με ιστορικό καρκίνου του μαστού στην οικογένεια ( συγγενείς α’ βαθμού ) ή θετικό γονιδιακό έλεγχο δεν θεωρούνται κατάλληλες υποψήφιες για αύξηση του μαστού με ενθέματα.
          Εδώ η γνώμη του ογκολόγου ιατρού υπερισχύει της ανάγκης για βελτίωση της εμφάνισης του μαστού με τη χρήση ενθεμάτων.
        </p>
        <h4>3. Σχετικά με την μετεγχειρητική πορεία </h4>
        <p>
          Η γυναίκα μπορεί να εξέλθει από το νοσοκομείο το ίδιο βράδυ ή να παραμείνει για μία νύχτα.
        </p>
        <p>
          Μπορεί να έχουν τοποθετηθεί παροχετεύσεις για 1-2 24ωρα κι αυτό εξαρτάται κυρίως από τη θέση που θα τοποθετηθούν τα ενθέματα.
        </p>
        <p>
          Η επιστροφή στην εργασία γίνεται συνήθως μετά από 7-10 μέρες.
        </p>
        <p>
          Η γυναίκα, συστήνεται να φορά ένα ειδικό στηθόδεσμο μετά την επέμβαση και η όποια αθλητική δραστηριότητα έχει, αυτή να γίνεται μετά την παρέλευση 1-1,5 μήνα.
        </p>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ
          ΕΠΙΠΛΟΚΕΣ ΠΟΥ ΜΠΟΡΟΥΝ ΝΑ ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='reduction'>ΜΕΙΩΤΙΚΗ ΜΑΣΤΩΝ <br/>( BREAST REDUCTION )</h3>
        <p>
          Η σμίκρυνση των μαστών είναι μία επέμβαση που στοχεύει στην αφαίρεση τμήματος του παρεγχύματος του μαστού, του λίπους και δέρματος καθώς και στην τοποθέτηση της θηλής ψηλότερα στη φυσική
          της θέση μετά την ανόρθωση, σε περιπτώσεις όπου ο μαστός κρίνεται μεγάλος.
        </p>
        <h4>1. Σχετικά με τη μεγαλομαστία </h4>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture_missing">
              Το μέγεθος του γυναικείου στήθους καθορίζεται από παράγοντες, όπως η κληρονομικότητα, το σωματικό βάρος και η ορμονική επίδραση. Ταυτόχρονα το μεγάλο στήθος συνοδεύεται και από αυξημένο
              βάρος. Πολλές φορές μιλάμε για μεγαλομαστία ή γιγαντομαστία, αναδεικνύοντας το μέγεθος του προβλήματος. Οι μεγάλοι μαστοί σε μια γυναίκα επιδρούν αρνητικά στην καθημερινότητά της και
              πολλές φορές και στην κοινωνικοποίησή της αφού δεν της επιτρέπουν να ντυθεί με τα ρούχα που επιθυμεί ή να μετέχει σε δραστηριότητες όπως είναι η άθληση, ο χορός κλπ. Αλλά πέραν των
              εμφανισιακών επιπτώσεων μπορούν να δημιουργήσουν και συνδέονται αρκετά συχνά, με διάφορα προβλήματα υγείας, όπως είναι ο πόνος στην πλάτη, τον αυχένα και την μέση, η δερματίτιδα και
              ερεθισμοί κάτω από το στήθος, αίσθημα βάρους, δύσπνοια, κεφαλαλγία, εντυπώματα και ενοχλήσεις από τις τιράντες του στηθόδεσμου. Παράλληλα ένας μεγάλος και βαρύς μαστός έχει μεγαλύτερες
              πιθανότητες να κρεμάσει και να «πέσει», αυξάνοντας έτσι το πρόβλημα που βιώνει η γυναίκα. Τελικό αποτέλεσμα όλων των παραπάνω είναι η ψυχολογική επιβάρυνση της γυναίκας και η επίδραση
              πάνω στην αυτοπεποίθηση της.
          </div>
        </div>
        <p>
          Εξίσου σημαντικό όμως είναι να αναφερθεί ότι και η παρακολούθηση των μεγάλων μαστών μπορεί να συνθέτει ένα σοβαρό πρόβλημα τόσο στα πλαίσια της τακτικής κλινικής εξέτασής τους όσο και στον
          απεικονιστικό έλεγχο που χρειάζεται να γίνεται περιοδικά, ιδιαίτερα όταν συνυπάρχουν, ως συνήθως, πολλά και πυκνά ινοκυστικά στοιχεία.
        </p>
        <h4>2. Σχετικά με την επέμβαση  </h4>
        <p>
          Η επέμβαση γίνεται με γενική αναισθησία και διαρκεί 3-4 ώρες, ανάλογα με το μέγεθος των μαστών.
        </p>
        <p>
          Εφαρμόζονται διάφορες τεχνικές για την αφαίρεση των τμημάτων του μαστού και τη μεταφορά της θηλής στην φυσική της θέση που εξαρτάται από το μέγεθος των μαστών, τη συμμετρία τους και τις ουλές
          που θα αφήσουν μετεγχειρητικά. Έτσι αναφέρονται κάθετοι, οριζόντιοι, κεντρικοί και πλάγιοι κρημνοί ενώ οι ουλές που δημιουργούνται μπορεί να είναι κυκλικές ( περιθηλαίες ), περιθηλαίες με μία
          κάθετη ουλή, περιθηλαίες με κάθετη και μικρή οριζόντια, περιθηλαίες με κάθετη και οριζόντια τύπου L ή άγκυρας.
        </p>
        <h4>3. Σχετικά με την μετεγχειρητική πορεία  </h4>
        <p>
          Μετά την επέμβαση τοποθετούνται παροχετεύσεις που αφαιρούνται μετά από λίγα 24ωρα, ανάλογα με το περιεχόμενο τους.
        </p>
        <p>
          Η γυναίκα παραμένει στο νοσοκομείο για 1 βράδυ. Σε μικρότερης έκτασης πρόβλημα μπορεί και να εξέλθει αυθημερόν.
        </p>
        <p>
          Τα ράμματα αφαιρούνται στις 12-15 μέρες.
        </p>
        <p>
          Συστήνεται η χρήση ειδικού στηθόδεσμου για μερικές εβδομάδες έως μήνες ενώ η επιστροφή στην εργασία και ανάλογα με τη φύση αυτής γίνεται μετά από 7-10 μέρες. Η επαναφορά σε πλήρη δραστηριότητα
          συμπεριλαμβανομένης και της άθλησης συστήνεται να γίνει μετά τον μήνα.
        </p>
        <p>
          Μπορεί να παρατηρηθεί μείωση της αισθητικότητας της θηλής που στις περισσότερες περιπτώσεις είναι παροδική και διαρκεί μερικούς μήνες. Επιπλέον μπορεί να επηρεαστεί η δυνατότητα θηλασμού, που
          με τις σύγχρονες τεχνικές έχει περιοριστεί το ποσοστό αυτής της αδυναμίας. Αν και το αποτέλεσμα θεωρείται μόνιμο, η διακύμανση στο βάρος, η ύπαρξη μιας νέας εγκυμοσύνης αλλά και μια ορμονική
          ανισορροπία μπορεί να επηρεάσει εκ νέου το μέγεθος των μαστών. Γενικά συστήνεται η επέμβαση να γίνεται μετά τον προγραμματισμό των κυήσεων μιας γυναίκας, εκτός και αν άλλοι λόγοι όπως ψυχολογικοί
          και λειτουργικοί επιβάλλουν την επέμβαση σε νεότερες ηλικίες.
        </p>
        <p>
          Το μεγαλύτερο πρόβλημα που αφορά την επέμβαση της μείωσης των μαστών είναι η επούλωση του τραύματος. Αν και σχεδιάζονται οι τομές έτσι ώστε να είναι το λιγότερο δυνατόν εμφανείς και να κρύβονται
          κάτω από τον στηθόδεσμο, παραμένουν κόκκινες και πιο σκληρές για το αρχικό διάστημα, μαλακώνουν δε και αποχρωματίζονται σταδιακά με την πάροδο του χρόνου.  Ποτέ όμως δεν εξαφανίζονται πλήρως.
          Στην μετεγχειρητική αγωγή και για την πρόληψη εμφάνισης δύσμορφων ουλών χορηγούνται ειδικές επουλωτικές κρέμες, φύλλα σιλικόνης, ειδικοί επίδεσμοι με πίεση στο επίπεδο των ουλών και αντιηλιακή προστασία.
        </p>
        <p>
          Παρόλα αυτά είναι αξιοσημείωτο το γεγονός ότι για την πλειοψηφία των γυναικών η ύπαρξη των ουλών χαρακτηρίζεται αμελητέα σε σχέση με το συνολικό όφελος που βιώνουν από τη σμίκρυνση των μαστών.
        </p>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ ΕΠΙΠΛΟΚΕΣ ΠΟΥ
          ΜΠΟΡΟΥΝ  ΝΑ  ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='lift'>ΑΝΟΡΘΩΣΗ ΜΑΣΤΟΥ <br/> ( BREAST LIFT / MASTOPEXY )</h3>
        <p>
          Είναι η επέμβαση, γνωστή και ως μαστοπηξία, που στόχο έχει την ανόρθωση του «πεσμένου» μαστού και βασίζεται στην αφαίρεση του δέρματος που περισσεύει, στην επαναδιευθέτηση του όγκου του μαστού και
          στήριξή του σε ανώτερη θέση μαζί με τη μεταφορά του συμπλέγματος θηλής – άλω.
        </p>
        <h4>1. Σχετικά με τον πτωτικό μαστό </h4>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture_missing">
              Το σχήμα του μαστού, πέρα από τα ιδιοσυστατικά χαρακτηριστικά της γυναίκας μπορεί να αλλάξει ως συνέπεια της εγκυμοσύνης, του θηλασμού και της αυξομείωσης του βάρους. Ιδιαίτερα κατά την περίοδο
              της εγκυμοσύνης, όταν λόγω ορμονικής «καταιγίδας» το μέγεθος των μαστών αυξάνει, διατείνονται οι σύνδεσμοι που συγκρατούν το μαστό και το δέρμα του και πολλές φορές διασπώνται, με αποτέλεσμα
              όταν ο μαστός επιστρέφει στον φυσιολογικό του όγκο μετά το θηλασμό, να μην έχει τη στήριξη που είχε και αναγκαστικά να κρεμάει λόγω και της βαρύτητας. Αντίστοιχα η ηλικία, η κληρονομικότητα
              και το βάρος παίζουν ρόλο στην αλλαγή του σχήματος και την πτώση του στήθους.
          </div>
        </div>
        <p>
          Επιπλέον λόγω της πίεσης από το πεσμένο στήθος, η διάμετρος της θηλαίας άλω μπορεί να αυξηθεί και να χρειάζεται νέα διαμόρφωση και αυτής.
        </p>
        <p>
          Η επέμβαση της ανόρθωσης του μαστού δεν μεταβάλει σημαντικά το μέγεθος ούτε γεμίζει τον άνω έσω πόλο του μαστού, ιδιαίτερα αν είναι μικρός ο όγκος του. Έτσι αν κάποια γυναίκα επιθυμεί να φαίνεται
          γεμάτο το μπούστο της, σ’ αυτή την περίπτωση μπορεί να χρειάζεται συνδυασμός ανόρθωσης και τοποθέτησης ενθέματος σιλικόνης.
        </p>
        <h4>2. Σχετικά με την επέμβαση </h4>
        <p>
          Η επέμβαση γίνεται με γενική αναισθησία υπό άσηπτες συνθήκες και η διάρκεια παραμονής στο νοσοκομείο είναι από μερικές ώρες έως ένα βράδυ.
        </p>
        <p>
          Αφαιρείται το δέρμα του μαστού που περισσεύει κατά τον κάτω πόλο του μαστού και το σχήμα του μαστού αναδιαμορφώνεται σε σχήμα κωνικό και η θηλή – άλως επιστρέφει ψηλότερα στην φυσιολογική της θέση.
          Αν χρειάζεται και έχει συμφωνηθεί τοποθετείται ένθεμα σιλικόνης πριν την αφαίρεση της περίσσειας του δέρματος, κι αυτό γιατί θα πρέπει να συνυπολογιστεί η αύξηση του όγκου του μαστού με το ένθεμα
          πριν την όποια αφαίρεση. Η εγχείρηση τελειώνει με τη συρραφή των τομών που εγκαταλείπουν ουλές που μπορεί να είναι κυκλικές ( περιθηλαίες ), περιθηλαίες με μία κάθετη ουλή, περιθηλαίες με κάθετη
          και μικρή οριζόντια, περιθηλαίες με κάθετη και οριζόντια τύπου L ή άγκυρας.
        </p>
        <h4>3. Σχετικά με την μετεγχειρητική πορεία  </h4>
        <p>
          Τις πρωτες μέρες ο μαστός είναι διογκωμένος και η ύπαρξη πόνου ελέγχεται με απλά αναλγητικά. Στις περισσότερες περιπτώσεις δεν τοποθετούνται παροχετεύσεις, παρόλα αυτά αν κριθεί αναγκαίο μπορεί
          να τοποθετηθούν και να αφαιρεθούν μετά από λίγα 24ωρα, όταν το περιεχόμενο τους ελαττωθεί κάτω από 30 κ.εκ.
        </p>
        <p>
          Η γυναίκα φοράει ένα ειδικού τύπου στηθόδεσμο, που θα συνεχίσει να τον φοράει για μερικές βδομάδες.
        </p>
        <p>
          Τα ράμματα αφαιρούνται την 7η – 10η μέρα ανάλογα με την πορεία επούλωσης.
        </p>
        <p>
          Κάποιες φορές μπορεί να παρατηρηθεί μικρή φλεγμονή ή διάσταση στο σημείο ένωσης της κάθετης με την οριζόντια τομή, η οποιά αντιμετωπίζεται συντηρητικά.
        </p>
        <p>
          Ο έλεγχος μετεγχειρητικά αφορά τον αποκλεισμό εμφάνισης συλλογής ή αιματώματος στον χειρουργημένο μαστό και στην πορεία επούλωσης των ουλών. Οι ουλές είναι κάτι μόνιμο πλέον και στις περισσότερες
          των περιπτώσεων μετά την πάροδο των πρώτων μηνών αποχρωματίζονται και παίρνουν το χρώμα του γύρω δέρματος. Ειδική αγωγή εφαρμόζεται πάντα στις ουλές αυτές για την πρόληψη εμφάνισης δυσμορφίας.
        </p>
        <p>
          Οι συνήθεις δραστηριότητες και άθλησης συστήνεται να επιστρέφουν μετά την πάροδο ενός μήνα από την εγχείρηση.
        </p>
        <p>
          Η παρακολούθηση του μαστού ακολουθεί την πρακτική που εφαρμόζεται στις περιπτώσεις σμίκρυνσης και αύξησης του μαστού.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='more'>ΑΛΛΕΣ ΕΠΕΜΒΑΣΕΙΣ ΣΤΟ ΜΑΣΤΟ</h3>
        <p>
          Συνδυασμοί επεμβάσεων μπορεί να γίνουν σε περιπτώσεις ασυμμετρίας των μαστών με επιλεκτική μείωση, αύξηση ή ανόρθωση στον κάθε μαστό ανάλογα με τη φύση του προβλήματος. Ακόμη πιο εξειδικευμένες
          επεμβάσεις αφορούν το σύμπλεγμα θηλής-άλω για συγγενή ή επίκτητα αίτια που τα συνηθέστερα είναι η εισολκή των θηλών και η υπερτροφία αυτών.
        </p>
        <p>
          <b>ΕΙΣΟΛΚΗ ΘΗΛΗΣ:</b>   <br/>
          Σε αυτή την περίπτωση η θηλή δεν προβάλλει φυσιολογικά πάνω από την άλω ούτε και κατά τον ερεθισμό της, αλλά αντίθετα παραμένει βυθισμένη μέσα στην άλω. Ως αίτιο περιγράφεται η αδυναμία ανάπτυξης
          και ωρίμανσης των πόρων του μαζικού αδένα κατά την περίοδο ανάπτυξής της, με αποτέλεσμα να ανώριμες πια χορδές να μην επιμηκύνονται αλλά να τραβούν προς τα μέσα όλη τη θηλή. Από τη στιγμή δηλαδή
          που δεν καταλήγουν πόροι στη θηλή, η ασθενής δεν μπορεί να θηλάσει. Η θεραπεία είναι μόνο χειρουργική. Η επέμβαση μπορεί να γίνει με τοπική αναισθησία, διαρκεί περί τα 20-30 λεπτά και η τομή
          προσπέλασης γίνεται μέσα από το σύμπλεγμα της θηλής-άλω, εφαρμόζοντας διαφορετικές τεχνικές που υπάρχουν. Είναι ευνόητο ότι η αποκατάσταση αφορά μόνο την εμφάνιση της θηλής και όχι τη λειτουργικότητά
          της κι έτσι η γυναίκα δεν θα μπορεί να θηλάσει και μετά από μία τέτοια επέμβαση.
        </p>
        <p>
          <b>ΥΠΕΡΜΕΓΕΘΗΣ ΘΗΛΗ:</b>   <br/>
          Σε αυτή την περίπτωση η θηλή είναι μεγάλη και μπορεί να φορά τη μία ή και τις δύο θηλές. Το πρόβλημα μπορεί να είναι τόσο μεγάλο που να δημιουργεί και πρόβλημα ένδυσης στη γυναίκα. Η αντιμετώπισή της
          είναι χειρουργική και γίνεται με τοπική αναισθησία.
        </p>
        <p>
          <b>ΥΠΕΡΜΕΓΕΘΗΣ ΑΛΩΣ:</b>   <br/>
          Σε αυτή την περίπτωση η θηλαία άλως είναι μεγάλη και δυσανάλογη σε σχέση με την επιφάνεια του μαστού. Η αντιμετώπιση κι εδώ είναι χειρουργική και γίνεται με τοπική αναισθησία, αφαιρώντας ένα μικρό
          δακτυλίδι από το εσωτερικό της άλω μικραίνοντας τη διάμετρό της.
        </p>
        <p>
          <b>ΥΠΕΡΑΡΙΘΜΟΣ ΘΗΛΗ:</b>   <br/>
          Σε αυτή την περίπτωση και κατά μήκος της γαλακτοφόρου γραμμής που ξεκινά από το ύψος του ώμου και καταλήγει στη βουβωνική χώρα, από την μία ή την άλλη πλευρά, μπορεί να υπάρχει μία μικρή εξωτερική
          διόγκωση, που θυμίζει θηλή. Μπορεί να συνυπάρχει ή να είναι χωρίς έκτοπο μαζικό αδένα. Η αντιμετώπιση είναι και πάλι χειρουργική και θα πρέπει να αποκλειστεί η ύπαρξη τυχόν δερματικής βλάβης
          διαφορετικής από μια υπεράριθμη θηλή. Γι αυτό και ότι αφαιρείται, στέλνεται προς βιοψία.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='abdominoplasty'>ΚΟΙΛΙΟΠΛΑΣΤΙΚΗ <br/> (Abdominoplasty, tummy tuck, mini abdominoplasty)</h3>
        <p>
          Είναι η επέμβαση στο τμήμα της κοιλιάς που στόχο έχει την αποκατάσταση των αποδυναμωμένων κοιλιακών μυών, την αφαίρεση της περίσσειας του δέρματος, τη μείωση του περιττού λίπους και την απόδοση ενός
          λεπτότερου και ομαλότερου περιγράμματος.
        </p>
        <p>
          Απευθύνεται κυρίως σε όσους έχουν χαλάρωση στο δέρμα της κοιλιάς συνήθως μετά από μεγάλη απώλεια βάρους ή μετά τις εγκυμοσύνες στις γυναίκες.
        </p>
        <h4>1. Σχετικά με την περιοχή της κοιλιάς</h4>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A4e1} alt="tummy tuck" className="img-width-small" />
          </div>
          <div className="flexitem_text_w_picture_wide">
              <p>
                Το πρόσθιο κοιλιακό τοίχωμα περιβάλλει τα σπλάχνα της κοιλιάς και αποτελείται από το μυϊκό τοίχωμα, το λίπος και το δέρμα.
              </p>
              <p>
                Η μεγάλη διάταση των ιστών  και της περιμέτρου είτε από την άθροιση του λίπους ή από την εξέλιξη μιας εγκυμοσύνης οδηγεί μετά από την απώλεια βάρους ή τον τοκετό σε αποδυνάμωση ή διάσταση των
                μυών, σε χαλάρωση στο δέρμα που μπορεί ακόμη και να κρέμεται καθώς και στην εμφάνιση ραγάδων στην επιφάνειά του.
              </p>
          </div>
        </div>
        <p>
          Αλλά και «λεπτά» άτομα μπορεί να παρουσιάζουν πρόβλημα χαλάρωσης στο δέρμα της κοιλιάς λόγω κληρονομικότητας, ηλικίας, προηγούμενων τοκετών  χωρίς μεγάλη αύξηση βάρους, ή προηγούμενων διακυμάνσεων σε αυτό.
        </p>
        <ul>
          Τι δεν προσφέρει μια κοιλιοπλαστική:
          <li>
            Δεν υποκαθιστά την απώλεια βάρους
          </li>
          <li>
            Δεν αφαιρεί όλες τις ραγάδες, παρά μόνον εκείνες που βρίσκονται κάτω από τον ομφαλό και περιλαμβάνονται στο δέρμα που θα αφαιρεθεί
          </li>
          <li>
            Παρόλο που το αποτέλεσμα θεωρείται μόνιμο, αυτό μπορεί να επηρεαστεί από νέα διακύμανση του βάρους ή μια ενδεχόμενη μελλοντική εγκυμοσύνη. Γι’ αυτό συστήνεται να γίνει η επέμβαση μετά τον προγραμματισμό των κυήσεων
          </li>
        </ul>
        <h4>2. Σχετικά με την επέμβαση </h4>
        <p>
          Αυτή γίνεται με γενική αναισθησία και τον απαραίτητο προεγχειρητικό έλεγχο. Η τομή σχήματος ημισεληνοειδούς, γίνεται στο κάτω μέρος της κοιλιάς λίγο πάνω από το εφήβαιο ή επί παλαιάς καισαρικής τομής και επεκτείνεται
          στα πλάγια ανάλογα με την έκταση της χαλάρωσης.
        </p>
        <p>
          Η παρασκευή του δερμοϋποδόριου κρημνού φτάνει μέχρι το ύψος της ξιφοειδούς απόφυσης στο στέρνο. Στη συνέχεια γίνεται η ενδυνάμωση του μυϊκού τοιχώματος με ράμματα καθώς και η επίτευξη της γράμμωσης που είναι ιδιαίτερα
          εμφανής σε λεπτότερα άτομα. Αν συνυπάρχει κήλη στον ομφαλό ή στη μέση γραμμή γίνεται ταυτόχρονη αποκατάσταση αυτής. Μπορεί να συνδυαστεί με λιποαναρρόφηση ιδιαίτερα προς τα πλάγια της τομής.
        </p>
        <p>
          Τέλος αφαιρείται το δερμολιπώδες κομμάτι που περισσεύει και γίνεται η τελική συρραφή του δέρματος σε στρώματα  καθώς και του ομφαλού που αποκαλύπτεται πλέον στην καινούργια του θέση στη μέση γραμμή.
        </p>
        <h4>3. Σχετικά με την μετεγχειρητική πορεία  </h4>
        <p>
          Η διάρκεια παραμονής στο νοσοκομείο είναι συνήθως ένα βράδυ. Οι παροχετεύσεις που τοποθετούνται στην επέμβαση αφαιρούνται μετά από μερικές μέρες, όταν έχει μειωθεί το περιεχόμενό τους.
        </p>
        <ul>
          Η συνήθης πορεία μετά την επέμβαση χαρακτηρίζεται:
          <li>
            Ήπιο πρήξιμο στο κατώτερο μέρος της κοιλιάς και του εφηβαίου που υποχωρεί σταδιακά με την πάροδο του χρόνου
          </li>
          <li>
            Μείωση της αισθητικότητας στο κάτω μέρος της κοιλιάς που επανέρχεται μετά από λίγους μήνες
          </li>
          <li>
            Οι ουλές αρχικά παραμένουν κόκκινες και σκληρές, με την πάροδο όμως του χρόνου και κατάλληλη τοπική αγωγή απαλύνονται και παίρνουν το χρώμα του γύρω δέρματος. Η διαδικασία αυτή της επούλωσης είναι ξεχωριστή για το
            κάθε άτομο
          </li>
          <li>
            Σε ελάχιστες περιπτώσεις μπορεί να παρουσιαστεί τις πρώτες μέρες μετά την αφαίρεση των παροχετεύσεων, μικρή  συλλογή υγρού ( seroma ) στο κάτω μέρος του κοιλιακού τοιχώματος που αφαιρείται με μία απλή παρακέντηση
            στο ιατρείο
          </li>
        </ul>
        <p>
          Τον πρώτο μήνα, η εφαρμογή ειδικής ζώνης στην κοιλιά είναι υποχρεωτική και οι όποιες δραστηριότητες άθλησης, χορού κλπ συστήνεται να ξεκινούν πάλι μετά από 1,5 μήνα από την επέμβαση.
        </p>
        <p>
          Σε ενδεικνυόμενες περιπτώσεις μπορεί να γίνει η «μίνι» κοιλιοπλαστική, ενώ σε επεμβάσεις μετά από βαριατρική επέμβαση και μεγάλη απώλεια βάρους οι ουλές της κοιλιοπλαστικής μπορεί να έχουν σχήμα ανεστραμμένου Τ.
        </p>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ ΕΠΙΠΛΟΚΕΣ ΠΟΥ ΜΠΟΡΟΥΝ
            ΝΑ ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
      </div>
)};

export default Torso
