import React, { Component, useRef} from 'react';
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import { HashLink } from 'react-router-hash-link';
import A2a1 from './img/A2a1.jpg';
import A2a2 from './img/A2a2.jpg';
import A2b1 from './img/A2b1.jpg';
import A2b2a from './img/A2b2a.jpg';
import A2b2b from './img/A2b2b.jpg';
import A2b2c from './img/A2b2c.jpg';
import A2c1a from './img/A2c1a.jpg';
import A2c1b from './img/A2c1b.jpg';
import A2c2a from './img/A2c2a.jpg';
import A2c2b from './img/A2c2b.jpg';
import A2c2c from './img/A2c2c.jpg';
import A2c4 from './img/A2c4.jpg';
import A2c5a from './img/A2c5a.jpg';
import A2c5b from './img/A2c5b.jpg';
import A2c6 from './img/A2c6.jpg';
import A2c7a from './img/A2c7a.jpg';
import A2c7b from './img/A2c7b.jpg';
import A2c8a from './img/A2c8a.jpg';
import A2c8b from './img/A2c8b.jpg';
import A2c8c from './img/A2c8c.jpg';
import A2c9a from './img/A2c9a.jpg';
import A2c9b from './img/A2c9b.jpg';
import A2c10a from './img/A2c10a.jpg';
import A2c10b from './img/A2c10b.jpg';
import A2d1 from './img/A2d1.jpg';
import A2d2 from './img/A2d2.jpg';
import A2d3 from './img/A2d3.jpg';

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Faceslight = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
      <ScrollToTopOnMount/>
        <h1 ref={topRef}> ΠΡΟΣΩΠΟ <br/> Ελαχιστα Επεμβατικες θεραπειες </h1>
        <div className="therapy-links">
            <div className="therapy"> <HashLink to="#mesotherapy" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Μεσοθεραπεία <br/> με τη μέθοδο <br/> Μicroneedling</HashLink> </div>
            <div className="therapy"> <HashLink to="#autologous_mesotherapy" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Αυτόλογη <br/> Μεσοθεραπεία</HashLink> </div>
            <div className="therapy"> <HashLink to="#laser" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Τα LASER στην Υπηρεσία της Ομορφιάς</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#PDO-COG" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>PDO και COG <br/> ιατρικά νήματα</HashLink> </div>
        </div>
        <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
        <h3 id="mesotherapy">ΜΕΣΟΘΕΡΑΠΕΙΑ <br/> με τη μέθοδο Microneedling <br/> ή ενέσιμη σε συνδυασμό με το Υπερβαρικό Οξυγόνο</h3>
        <h4> 1.	Σχετικά με τη Μεσοθεραπεία με Microneedling </h4>
        <p>
          Με τη μεσοθεραπεία το δέρμα δείχνει λαμπερότερο, πιο υγιές και ενυδατωμένο.
          Τυπικά δύο με τρεις συνεδρίες χρειάζονται για να αναδειχτούν τα αποτελέσματα, αν και λόγω των μικρονυγμών και το αγγειακό ερέθισμα,
          το δέρμα δείχνει λαμπερότερο ήδη από την πρώτη φορά. Ο αριθμός των συνεδριών ποικίλει από άτομο σε άτομο ανάλογα με την ηλικία, τον
          τρόπο ζωής και την βαρύτητα των αλλοιώσεων στο δέρμα.
        </p>
        <p>
          Η μεσοθεραπεία δεν είναι μέθοδος γεμίσματος αλλά προκαλεί την ανάπλαση του δέρματος αυξάνοντας την ενυδάτωση αλλά και δημιουργώντας
          μία θετική επίδραση στο μικρο-περιβάλλον για τους ινοβλάστες, που ευθύνονται για την παραγωγή κολλαγόνου και ελαστίνης.
        </p>
        <p>
          Και θα πρέπει να είναι ξεκάθαρο ότι ενώ η βουτυλινική τοξίνη και τα fillers βελτιώνουν τοπικά το δέρμα, η μεσοθεραπεία δρα συνολικά
          στην επιφάνειά του.
        </p>
        <h4>2. Σχετικά με την θεραπεία</h4>
        <p>
          Υπό άσηπτες συνθήκες διενεργείται το μικροτραύμα στο δέρμα με το microneedling και δια μέσου των μικρο-οπών απορροφούνται τα
          αποστειρωμένα σκευάσματα μεσοθεραπείας που επαλείφουμε και τα οποία ανάλογα με το πρόβλημα του καθενός ποικίλλουν από υαλουρονικό
          οξύ, πολυβιταμινούχα σκευάσματα, αμινοξέα έως και συνδυασμό αυτών. <br/>
          <br/> Η μέθοδος συνδυάζεται με μία ή δύο συνεδρίες στο Θάλαμο Υπερβαρικού Οξυγόνου.
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2a1} alt="" className="img-width-medium" />
          </div>
          <div className="flexitem_a">
            <img src={A2a2} alt="" className="img-width-medium" />
          </div>
        </div>
        <p>
          Η Υπερβαρική Οξυγονοθεραπεία προσφέρει μοναδικά θεραπευτικά οφέλη γιατί :
        </p>
        <ul>
          <li>Επηρεάζει θετικά οποιοδήποτε τραύμα  ( ισχαιμικής- φλεγμονώδους  - τραυματικής αιτιολογίας ) </li>
          <li>Επιταχύνει δια μέσου του μηχανισμού επούλωσης την ανάρρωση, αναγέννηση και ενίσχυση της άμυνας του οργανισμού </li>
          <li>Αυξάνει την αποτελεσματικότητα  των προηγούμενα εφαρμοσμένων θεραπευτικών χειρισμών  που στην προκειμένη περίπτωση
           αφορούν επεμβάσεις πλαστικές επεμβάσεις ή μη χειρουρργικές θεραπευτικές μεθόδους ανανέωσης του δέρματος και </li>
          <li>Διασφαλίζει τη διάρκεια των αποτελεσμάτων  </li>
        </ul>
        <h4>3.	Πού εφαρμόζουμε  τη θεραπεία αυτή</h4>
        <p>
          Σε γυναίκες ηλικίας 45-55 ετών με εμφανή τα σημάδια φωτογήρανσης στο πρόσωπο και ως συμπληρωματική θεραπεία μετά από
          επεμβάσεις πλαστικής χειρουργικής στο πρόσωπο με στόχο τη βελτίωση της υφής του δέμρατος.
          <br/> Ειδικότερα, εφαρμόζεται σε
        </p>
        <ul>
          <li>Πιο σοβαρή φωτογήρανση, μέσης ηλικίας</li>
          <li>Δέρμα με εμφανείς ρυτίδες</li>
          <li>Ήπια χαλάρωση και ελάττωση ελαστικότητας</li>
          <li>Δυσχρωμίες ως και πιο</li>
          <li>Εντοπισμένες βαθιές ρυτίδες στο μεσόφρυο, μέτωπο, παρειές</li>
          <li>Εμφανή τραχύτητα δέρματος και αφυδάτωση</li>
          <li>Aπώλεια ελαστικότητας</li>
          <li>Oυλές ακμής</li>
        </ul>
        <h4>4.	Τι επιτυγχάνουμε με τη θεραπεία</h4>
        <p>
          Με τη θεραπεία αυτή επιτυγχάνουμε γρήγορη και εντυπωσιακή αναστροφή των Βλαβών στο δέρμα, προσδίδοντας στο δέρμα
          νεανικότερη όψη, επαναφέρεται η λάμψη, η ενυδάτωση και η σφρυγηλότητα του , τονίζεται η ελαστικότητά του και η
          φρεσκάδα του. Το αποτέλεσμα χαρακτηρίζεται από τη διάρκεια του ενώ για τη μέγιστη αποτελεσματικότητα, ο αριθμός
          των συνεδριών κυμαίνεται από 4 - 8 θεραπείες συνολικά ανά 10-15 μερίπου περίπου και ανάλογα με το πρόβλημα που
          παρουσιάζει ο καθένας. Το σημαντικότερο όμως είναι το αποτέλεσμα αυτό κερδίζεται με ελάχιστα επεμβατικό τρόπο
          ενώ η άνάρρωση με τη χρήση του Υπερβαρικού Οξυγόνου διαρκει από μία έως δύο μέρες.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id="autologous_mesotherapy">Αυτόλογη Μεσοθεραπεία  <br/> με ταυτόχρονη χρήση Υπερβαρικού Οξυγόνου </h3>
        <h4> 1.	Σχετικά με την αυτόλογη μεσοθεραπεία ( PRP ) </h4>
        <p>
          Αποτελεί μία State-of-the-art τεχνική, βασιζόμενη στην επιστήμη της βιολογίας και αποτελεί μία από τις νεότερες
          εξελίξεις στη θεραπεία της γήρανσης. Με τη θεραπεία αυτή το δικό σου αίμα χρησιμοποιείται για να ενεργοποιήσει
          τους φυσικούς μηχανισμούς στα κύτταρα του δέρματος. Το αίμα περιέχει λευκά και ερυθρά κύτταρα και αιμοπετάλια.
          Τα PRP προέρχονται από τα αιμοπετάλια που βρίσκονται στον ορρό του αίματος. Τα αιμοπετάλια, πλούσια σε αυξητικούς
          παράγοντες, αποτελούν τους φυσικούς ενεργειακούς δότες για το δέρμα μας. Έτσι, οι αυξητικοί παράγοντες εγχυόμενοι
          στο δέρμα ξανά, διεγείρουν την παραγωγή κολλαγόνου και ελαστίνης και προάγουν την αντιοξειδωτική δράση και την
          ενυδάτωση.
        </p>
        <h4>2. Σχετικά με την θεραπεία PRP</h4>
        <p>
          Στο κέντρο μας χρησιμοποιείται κλειστό σύστημα μονού σωλήνα για την άσηπτη συλλογή του αίματος από μια φλέβα,
          την επεξεργασία του δείγματος στη φυγόκεντρο και την άντληση του ορρού πλούσιου σε αιμοπετάλια και αυξητικούς
          παράγοντες για την περαιτέρω χρήση. Έτσι το δείγμα του αίματος δεν έρχεται σε επαφή με τον αέρα σε καμία φάση
          της επεξεργασίας  με αποτέλεσμα να εξαλείφεται ο  κίνδυνος επιμόλυνσης του υλικού μας.
        </p>
        <div className="sub_flex">
          <div className="sub_onepic">
            <img src={A2b1} alt="growth-factors" className="GFtable" />
          </div>
        </div>
        <p>
          Με τη βοήθεια του microdermabrashion ή του microneedling το δέρμα προετοιμάζεται υπό άσηπτες συνθήκες για να
          δεχθεί το κατάλληλα προετοιμασμένο πλούσιο σε αιμοπετάλια και αυξητικούς παράγοντες υλικό μας, που εγχύεται
          σε συγκεκριμένες θέσεις, στο πρόσωπο και το λαιμό. Με αυτόν τον τρόπο ενεργοποιούνται στο δέρμα οι φυσικοί
          κυτταρικοί μηχανισμοί για να παραχθεί το νέο-κολλαγόνο και να δράσει αναγεννητικά. Μπορεί να συνδυαστεί με
          fillers ή ακόμη καλύτερα με <b>Υπερβαρική Οξυγονοθεραπεία</b> για ακόμη πιο άμεσα και εντυπωσιακά αποτελέσματα.
        </p>
        <p><b>STATE OF THE ART Θεραπευτική προσέγγιση:</b></p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2b2a} alt="PRP-therapy-a" className="img-width-small" />
          </div>
          <div className="flexitem_a">
            <img src={A2b2b} alt="PRP-therapy-b" className="img-width-small" />
          </div>
          <div className="flexitem_a">
            <img src={A2b2c} alt="PRP-therapy-c" className="img-width-small" />
          </div>
        </div>
        <h4>3.	Πού εφαρμόζουμε  τη θεραπεία αυτή</h4>
        <p>
          Η αυτόλογη μεσοθεραπεία ολοκληρώνεται σε μία συνεδρία ακόμη κι αν συνδυαστεί με την Υπερβαρική Οξυγονοθεραπεία.
          Εφαρμόζεται με εντυπωσιακά αποτελέσματα σε περιπτώσεις όπου παρατηρείται:
        </p>
        <ul>
          <li>Έντονα φωτογηρασμένο δέρμα</li>
          <li>Ρυτίδες και σημάδια ελάστωσης του δέρματος</li>
          <li>Αφυδατωμένο και τραχύ δέρμα</li>
          <li>Χαλάρωση και απώλεια ελαστικότητας</li>
          <li>Σημάδια ακμής και ουλές</li>
          <li>Εμφανή τραχύτητα δέρματος και αφυδάτωση</li>
          <li>Aπώλεια ελαστικότητας</li>
          <li>Τριχόπτωση</li>
        </ul>
        <h4>4.	Αυτόλογη Μεσοθεραπεία για την ανάπτυξη τριχών</h4>
        <p>
          Η θεραπεία της τριχόπτωσης με PRP αποτελεί μία χρυσή λύση για την επανεμφάνιση των τριχών. Το πλούσιο σε αυξητικούς
          παράγοντες πλάσμα ενεργοποιεί τη δραστηριότητα στα τριχοθυλάκια για την αναγέννηση των τριχών. Μεγάλος αριθμός
          επιστημονικών μελετών έχει αναδείξει την αποτελεσματικότητα της μεθόδου και αφορά τόσο στο πάχος των τριχών όσο
          και στην αναγεννητική φάση στον κύκλο της τρίχας. Οποιοσδήποτε έχει το πρόβλημα της τριχόπτωσης είναι και ένας
          καλός υποψήφιος για τη συγκεκριμένη θεραπεία. Ο συνδυασμός με το Υπερβαρικό Οξυγόνο εξασφαλίζει το αποτέλεσμα,
          από τη στιγμή που ενεργοποιείται ο αγγειακός παράγοντας και ενδυναμώνεται το μικροπεριβάλλον στη ρίζα του θύλακα
          της τρίχας.
        </p>
        <h4>5.	Τι επιτυγχάνουμε με τη θεραπεία</h4>
        <p>
          Η μοναδική αυτή θεραπεία προσφέρει εντυπωσιακά αποτελέσματα στην αναγέννηση του δέρματος, χρησιμοποιώντας το δικό
          μας αίμα και διεγείροντας φυσικούς μηχανισμούς ανάπλασης. Με τη χρήση του αυτόλογου ιστού, μηδενίζεται ο κίνδυνος
          αλλεργικής αντίδρασης και δημιουργίας κοκκιωμάτων από αντίδραση ξένου σώματος. Ο συνδυασμός του με το Υπερβαρικό
          Οξυγόνο πολλαπλασιάζει την αποτελεσματικότητα της μεθόδου και διαφυλάσσει το εντυπωσιακό αποτέλεσμα που επιτυγχάνεται.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id="laser">Τα LASER στην Υπηρεσία της Ομορφιάς</h3>
        <h4>1.	Σχετικά με την τεχνολογία LASER στην Αισθητική Χειρουργική </h4>
        <p>
          Βασικό στοιχείο της εξέλιξης των εφαρμογών των Laser τα τελευταία χρόνια, αποτελεί το φαινόμενος της εκλεκτικής
          φωτοθερμόλυσης. Βασίζεται στο γεγονός οτι κάθε μόριο της ύλης, έχει τη δική του θερμοχωρητικότητα, που εξαρτάται
          με τη σειρά της από το μέγεθος και το σχήμα του. Έτσι ακριβώς συμβαίνει και με τα κύτταρα του οργανισμού. Ειναι
          ικανά να αντέξουν στην έκθεση σε μεγάλες θερμοκρασίες, πάντα όμως σε συνάρτηση με τη χρονική διάρκεια αυτής της
          έκθεσης. Μπορεί δε να υποστούν ανεπανόρθωτη βλάβη στην περίπτωση που η ενέργεια με το Laser δοθεί με τέτοιο τρόπο
          ώστε να μην προλάβουν να χάσουν το 50% της θερμοκρασίας που έχουν λάβει. Ακριβώς σ’ αυτό  το γεγονός βασίστηκε
          και το φαινόμενο της εκλεκτικής φωτοθερμόλυσης. Ουσιαστικά περιγράφει την έκθεση ενός κυττάρου-στόχου στην ενέργεια
          του Laser με συγκεκριμένο μήκος κύματος, με αποτέλεσμα η απορρόφηση της να προκαλέσει τέτοια αύξηση της θερμοκρασίας
          ώστε να καταστραφεί το κύτταρο, με την ελάχιστη όμως δυνατή βλάβη του γύρω ιστού.
        </p>
        <p>
          Το φαινόμενο αυτό, έχει εφαρμογές σε διάφορες κλινικές οντότητες που αφορούν βλάβες του δέρματος όπως είναι οι
          αγγειακές βλάβες, οι μελαγχρωματικές βλάβες, τα τατουάζ και  η υπερβολική και ανεπιθύμητη τριχοφυία αλλά και οι
          αλλοιώσεις από τη γήρανση του δέρματος όπως ρυτίδες, χαλάρωση και απώλεια της ελαστικότητας. Βασίζεται δε, στην
          ικανότητα που έχουν τα Laser σε συγκεκριμένο μήκος κύματος που δρούν, να απορροφώνται από χρωστικές όπως είναι
          η μελανίνη, η αιμοσφαιρίνη και το νερό.
        </p>
        <h4>2.	Σχετικά με τις θεραπείες με IPL/LASER που εφαρμόζουμε</h4>
        <p>
          <b>ΦΩΤΟΑΝΑΓΕΝΝΗΣΗ του ΔΕΡΜΑΤΟΣ</b>
        </p>
        <p>
          <b>A.  με την τεχνολογία Starlux της PALOMAR</b>
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c1a} alt="IR capsule" className="img-width-small" />
          </div>
          <div className="flexitem_a">
            <img src={A2c1b} alt="O2 chamber" className="img-width-medium" />
          </div>
        </div>
        <p>
          Η διαδικασία της φωτοαναγέννησης του δέρματος χρησιμοποιεί το παλμικό φως για τη θεραπεία μελαγχρωματικών και αγγειακών
          βλαβών και τη συνολική βελτίωση του τόνου του δέρματος. Οι <b>μελαγχρωματικές βλάβες</b> περιλαμβάνουν τις ηλιακές κηλίδες ή
          κηλίδες γήρατος και την γενικευμένη μελάγχρωση του δέρματος ενώ στις <b>αγγειακές βλάβες</b> περιλαμβάνονται οι τηλεαγγειεκτασίες
          γνωστές και ως αραχνοειδείς φλέβες στην επιφάνεια του δέρματος του προσώπου κόκκινης, μπλε ή μοβ απόχρωσης και η ροδόχρους
          ακμή του δέρματος του προσώπου με φλεγμονή και οίδημα του δέρματος. <br/>
          Η διαδικασία είναι απλή. Κατά τη διάρκεια της θεραπείας <i>παλμοί φιλτραρισμένης δέσμης φωτός</i> κατευθύνονται στο δέρμα και
          απορροφώνται από τη μελανίνη  όσον αφορά τις μελαγχρωματικές βλάβες και την αιμοσφαιρίνη όσον αφορά τις αγγειακές. Έτσι
          για τις <i>μελαγχρωματικές βλάβες</i>, το φως θερμαίνει και διασπά τη χρωστική , σκουραίνει τη βλάβη και δημιουργεί μια ελαφρά
          κρούστα που πέφτει από μόνη της καθαρίζοντας την περιοχή μετά από δέκα μέρες περίπου.
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c2a} alt="IR capsule" className="img-width-large" />
          </div>
          <div className="flexitem_a">
            <img src={A2c2b} alt="O2 chamber" className="img-width-small" />
            <img src={A2c2c} alt="O2 chamber" className="img-width-small" />
          </div>
        </div>
        <p>
          <i>Ως προς τις αγγειακές</i>, το φως υπερθερμαίνει τα μικρά τριχοειδή που προκαλούν τις τηλεαγγειεκτασίες, τη ροδάνθη και άλλες
          βλάβες και η θερμότητα αυτή ρικνώνει τα αγγεία που γίνονται γκρι ή έντονα κόκκινα μετά τη θεραπεία και σβήνουν σιγά σιγά τις
          επόμενες δέκα μέρες.
        </p>
        <p>
          <b>Είναι οι θεραπείες επώδυνες;</b> <br/><br/>
          Χάρις στην τεχνολογία του παλμικού φωτός με μακρύ εύρος παλμού, η ενέργεια που διανέμεται στο δέρμα δεν υπερθερμαίνει την επιδερμίδα,
          κάνοντας έτσι τη θεραπεία πιο ασφαλή και  ανεκτική. Συνήθως η αίσθηση κατά τη διάρκεια της θεραπείας είναι παρόμοια με αυτή του
          χτυπήματος ενός λάστιχου στο δέρμα, χωρίς όμως να αναφέρεται ως αίσθημα πόνου από τους περισσότερους.
        </p>
        <p>
          <b>Υπάρχουν παρενέργειες από τη θεραπεία;</b> <br/><br/>
          Μπορεί να εμφανιστεί κοκκινίλα στο δέρμα παρόμοια αυτής του ερεθισμού μετά από έκθεση στον ήλιο που διαρκεί από μερικές ώρες έως
          λίγες μέρες.
        </p>
        <div className="sub_flex">
          <div className="sub_onepic">
            <img src={A2c4} alt="" className="img-width-large" />
          </div>
        </div>
        <p>
          <b>B.	StarLux Fractional Non-Ablative Skin Resurfacing</b>
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c5a} alt="" className="img-width-medium" />
          </div>
          <div className="flexitem_a">
            <img src={A2c5b} alt="" className="img-width-medium" />
          </div>
        </div>
        <p>
          Το σύστημα Starlux χρησιμοποιεί την κεφαλή Laser Lux1540 κλασματικής μη απολέπισης  (fractional non-ablative) του δέρματος, για να
          διανείμει τη δέσμη του φωτός στο δέρμα σαν μια παράταξη από μικροδέσμες που δημιουργούν στήλες πηγμένου ιστού μέσα στο δέρμα. Η
          δημιουργία αυτών των μικρο-στηλών ενεργοποιεί το φυσιολογικό μηχανισμό της επούλωσης που με τη σειρά του οδηγεί στην δημιουργία
          καινούργιου, υγιούς ιστού.<i>Έτσι ως αποτέλεσμα έχουμε ένα πιο νεανικό και σφριγηλό δέρμα.</i>
        </p>
        <div className="sub_flex">
          <div className="sub_onepic">
            <img src={A2c6} alt="" className="img-width-extra-large" />
          </div>
        </div>
        <p>
          <b>Ποιες περιοχές μπορούν να θεραπευθούν και από τι;</b> <br/><br/>
          Η θεραπεία αυτή μπορεί να γίνει σε οποιαδήποτε περιοχή του σώματος, συμπεριλαμβανόμενων αυτών  του προσώπου, του στέρνου, των χεριών
          και των μπράτσων. Έτσι <b>ρυτίδες προσώπου, ουλές ακμής, μετατραυματικές/μετεγχειρητικές και δύσμορφες ουλές, σημάδια φωτογήρανσης
          προσώπου, ραγάδες</b> είναι μερικές από τις ενδεικνυόμενες θεραπείες.
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c7a} alt="" className="img-width-medium" />
            <img src={A2c7b} alt="" className="img-width-medium" />
          </div>
        </div>
        <p>
          <b>Είναι οι θεραπείες επώδυνες;</b> <br/><br/>
          Με τη συγκεκριμένη τεχνολογία αποφεύγουμε  τη συνολική υπερθέρμανση του ιστού, έχοντας έτσι ελάχιστη ενόχληση από την εφαρμογή της
          θεραπείας
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c8a} alt="" className="img-width-large" />
          </div>
          <div className="flexitem_a">
            <img src={A2c8b} alt="" className="img-width-small" />
            <img src={A2c8c} alt="" className="img-width-small" />
          </div>
        </div>
        <p>
          <b>Τι περιμένουμε μετά τη θεραπεία;</b> <br/><br/>
        </p>
        <ul>
          <li>Στην περιοχή θεραπείας εμφανίζεται ερυθρότητα, οίδημα, θερμότητα και τέντωμα που υποχωρούν μετά από λίγες ώρες εως μέρες.</li>
          <li>Πολύ γρήγορη διαδικασία επούλωσης, με χρόνο αποθεραπείας λίγα το πολύ 24ωρα.</li>
          <li>Για να επιτύχουμε εντυπωσιακά αποτελέσματα χρειάζεται μία σειρά θεραπειών με μεσοδιαστήματα 2- 4 εβδομάδων, ανάλογα με το
          πρόβλημα που καλούμαστε να επιλύσουμε.</li>
        </ul>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A2c9a} alt="" className="img-width-small" />
            <img src={A2c9b} alt="" className="img-width-small-couple" />
          </div>
          <div className="flexitem_a">
            <img src={A2c10a} alt="" className="img-width-small" />
            <img src={A2c10b} alt="" className="img-width-small-couple2" />
          </div>
        </div>
        <p>
          Σήμερα που η γνώση στις εφαρμογές αυτών των LASER έχει εμπλουτιστεί από την πολύχρονη εμπειρία, πιο απαιτητικές θεραπείες μπορούν να
          σχεδιαστούν με το <b>συνδυασμό διαφορετικών τύπων LASER, fillers και PRP μεσοθεραπείας</b>. Επιπλέον η δυνατότητα εφαρμογής της <b>Αναπλαστικής
          Οξυγονοθεραπείας</b> στο Κέντρο μας επιταχύνει τα αποτελέσματα, ενεργοποιεί στο έπακρο το μηχανισμό δημιουργίας νέου κολλαγόνου και
          ελαστίνης στο δέρμα και διασφαλίζει τη διάρκειά τους.
        </p>
        <p>
          Έτσι το δέρμα στο πρόσωπο και το λαιμό δείχνει λαμπερό, ξεκούραστο με επαναφορά της ελαστικότητας και περιορισμό των ρυτίδων και της χαλάρωσης
          με ελάχιστο έως και μηδενικό χρόνο αποθεραπείας.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id="PDO-COG">PDO και COG ιατρικά νήματα </h3>
        <h4> 1.	Σχετικά με τα νήματα πολυδιοξανόνης (PDO) </h4>
        <p>
          Αποτελούν αποστειρωμένα ράμματα, 100% απορροφήσιμα, φτιαγμένα από πρωτεΐνη που είναι απόλυτα βιοαποδομήσιμη και βιοσυμβατή ( cat 3 medical device ).
          Έχουν ένδειξη σε ανεπιθύμητες ρυτίδες, χαλάρωση δέρματος ακόμη και κυτταρίτιδα δέρματος. Μπορούν να συνδυαστούν με τα COGs καθώς και με άλλες
          θεραπείες Botox, ενέσιμου υαλουρονικού οξέος ή IPL.
        </p>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture">
            Αποτελούν αποστειρωμένα ράμματα, 100% απορροφήσιμα, φτιαγμένα από πρωτεΐνη που είναι απόλυτα βιοαποδομήσιμη και βιοσυμβατή ( cat 3 medical device ).
            <br/><br/>Έχουν ένδειξη σε ανεπιθύμητες ρυτίδες, χαλάρωση δέρματος ακόμη και κυτταρίτιδα δέρματος.
            <br/><br/>Μπορούν να συνδυαστούν με τα COGs καθώς και με άλλες θεραπείες Botox, ενέσιμου υαλουρονικού οξέος ή IPL.
          </div>
          <div className="flexitem_a">
            <img src={A2d1} alt="PDO" className="img-height-long" />
          </div>
        </div>
        <h4>2. Σχετικά με την θεραπεία</h4>
        <p>
          Μετά τη λήξη του ιστορικού και αποκλεισμό ορισμένων παθολογικών καταστάσεων προγραμματίζεται η τοποθέτηση των νημάτων στο πρόσωπο και το λαιμό ή το
          ντεκολτέ, μόνα τους ή σε προγραμματισμένο συνδυασμό με άλλες θεραπείες.
        </p>
        <p>
          Η θεραπεία είναι σχεδόν ανώδυνη και ασφαλής χωρίς εναπομείνασες ουλές ή σημάδια . Έχουν πολύ μικρό χρόνο ανάρρωσης και μπορεί να επαναληφθεί μετά 2-3
          μήνες ανάλογα με το πρόβλημα και την ένδειξη.
        </p>
        <h4>3.	Σχετικά με την αποθεραπεία</h4>
        <p>
          Μπορεί να παρατηρηθεί μικρό οίδημα για μερικές μέρες στην περιοχή της θεραπείας και σπάνια μερικές μελανιές.
        </p>
        <p>
          Το αποτέλεσμα είναι σταδιακό αν και τα πρώτα σημάδια φαίνονται από τις πρώτες μέρες. Η παραγωγή κολλαγόνου και στήριξης των ιστών είναι ένα εξελικτικό
          φαινόμενο που διαρκεί μήνες μέχρι την ολοκλήρωσή του.
        </p>
        <br/><br/>
        <h4> 1.	Σχετικά με τα νήματα Πολυδιοξανόνης με αγκιστροειδή σχεδιασμό στην επιφάνειά τους ( COG ) </h4>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture">
            Aποτελούν αποστειρωμένα νήματα που πέραν της δημιουργίας κολλαγόνου προκαλούν και έλξη στους ιστούς με αποτέλεσμα να προκαλούν και ανορθωτικό αποτέλεσμα
            σε αυτούς.
            <br/><br/>Ενδείκνυται σε φωτογηρασμένα δέρματα, σε ηλικίες άνω των 35 ετών με σημαντικό βαθμό χαλάρωσης του δέρματος και εμφάνιση ρυτίδων.
            <br/><br/>Μπορούν να τοποθετηθούν με τη βοήθεια λεπτών βελόνων ή κανουλών.
          </div>
          <div className="flexitem_a">
            <img src={A2d2} alt="COG" className="img-height-long" />
          </div>
        </div>
        <h4>2. Σχετικά με την θεραπεία</h4>
        <p>
          Η θεραπεία χαρακτηρίζεται ανώδυνη με άμεσο αποτέλεσμα και χωρίς εναπομείνασες ουλές ή σημάδια.
        </p>
        <div className="sub_flex">
          <div className="sub_onepic">
            <img src={A2d3} alt="COG placement" className="img-width-large" />
          </div>
        </div>
        <p>
          Η θεραπεία είναι σχεδόν ανώδυνη και ασφαλής χωρίς εναπομείνασες ουλές ή σημάδια . Έχουν πολύ μικρό χρόνο ανάρρωσης και μπορεί να επαναληφθεί μετά 2-3
          μήνες ανάλογα με το πρόβλημα και την ένδειξη.
        </p>
        <h4>3.	Σχετικά με την αποθεραπεία</h4>
        <p>
          Μπορεί να παρατηρηθεί μικρό οίδημα για μερικές μέρες στην περιοχή της θεραπείας και σπάνια μερικές μελανιές.
        </p>
        <p>
          Συστήνεται η αποφυγή άμεσης έκθεσης στον ήλιο και έντονης άσκησης για μια βδομάδα.
        </p>
        <p>
          Ανάλογα με το βαθμό χαλάρωσης μπορεί να παρατηρηθεί μικρή πτύχωση του δέρματος στα σημεία εισόδου των νημάτων, που παρέρχεται μετά από λίγες μέρες.
        </p>
        <p>
          Το αποτέλεσμα είναι εξελισσόμενο και διαρκεί πέραν του έτους.
        </p>
        <p>
          Μπορεί να συνδυαστεί με άλλες θεραπείες για ακόμη καλύτερο και μεγαλύτερης διάρκειας αποτέλεσμα.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
      </div>
)};

export default Faceslight
