import React from 'react';
import lf1 from '../img/laserFast-1.jpg';
import lf2 from '../img/laserFast-2.jpg';
import lfa from '../img/laserFast-a.jpg';
import lfb from '../img/laserFast-b.jpg';
import lfc from '../img/laserFast-c.jpg';
import lfd from '../img/laserFast-d.jpg';
import lfe from '../img/laserFast-e.jpg';

const LaserFast = ({children}) => (
  <div className="pop_page">
    <div className="d-flex">
      <div className="f-item-media">
        <iframe width="500" height="300" src="https://www.youtube.com/embed/C-aYSY2rvz8"
                title="Laser F.A.S.T." frameborder="0" className="video-container"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
        </iframe>
      </div>
      <div className="f-item">
          Η γήρανση στο πρόσωπο και το σώμα ξεκινάει από την ηλικία των 20 και κάνει εμφανή τα πρώτα σημάδια της συνήθως γύρω στα 30.
          Έτσι εμφανίζονται αρχικά οι πρώτες ρυτίδες γύρω από τα μάτια και οι ρυτίδες έκφρασης στο μέτωπο και τις παρειές. Με την πάροδο του χρόνου,
          οι συνδετικοί σύνδεσμοι στο πρόσωπο χαλαρώνουν, το λίπος αναδιανέμεται, ο υποκείμενος οστέινος σκελετός λεπταίνει και χάνονται τα σημεία
          στήριξης οδηγώντας στο βάθεμα φυσικών αυλάκων όπως είναι η ρινοχειλική, στην εμφάνιση στατικών ρυτίδων και αυλάκων, στη χαλάρωση του δέρματος
          και την απώλεια του όγκου στα ζυγωματικά και τις παρειές καθώς και στην ασαφοποίηση στο περίγραμμα. Η αθροιστική δράση της ηλιακής ακτινοβολίας
          επιτείνει το πρόβλημα οδηγώντας σε εμφάνιση δυσχρωμιών, λέπτυνση της επιδερμίδας και ξηρότητα. Γενετική προδιάθεση, αυξομειώσεις στο βάρος,
          κακή διατροφή και κάπνισμα επιδεινώνουν ή επιταχύνουν τις αλλαγές αυτές. Αντίστοιχες αλλαγές συμβαίνουν στο λαιμό, το ντεκολτέ, στα άκρα και
          τον κορμό με κύρια έκφραση την αύξηση της χαλάρωσης στο δέρμα και την εμφάνιση τοπικού λίπους, ιδιαίτερα στο λαιμό και το κάτω μέρος του κορμού.
      </div>
    </div>
    <div>
      <p>
      Η θεραπεία με το <span className="modal-text-b">Laser F.A.S.T.<sup>®</sup></span> στοχεύει να αναστρέψει τα σημάδια αυτά της γήρανσης και να επιτύχει την:
      </p>
    </div>
    <div>
      <ul>
        <li>ανάπλαση των επιφανειακών αλλά και των βαθύτερων στοιβάδων του δέρματος,</li>
        <li>βελτίωση του τόνου του δέρματος,</li>
        <li>διάσπαση των πεπαχυσμένων συνδετικών διαφραγμάτων, που ευθύνονται για τη μειωμένη ελαστικότητα του δέρματος</li>
        <li>ενεργοποίηση της παραγωγής κολλαγόνου και ελαστίνης και</li>
        <li>τη λιπόλυση</li>
      </ul>
    </div>
    <h4 id='process'>Η ΔΙΑΔΙΚΑΣΙΑ</h4>
    <div className="d-flex">
      <div className="f-item">
          <p>
            Η θεραπεία <span className="modal-text-b">Laser F.A.S.T.<sup>®</sup></span> είναι αμιγώς ιατρική πράξη και πραγματοποιείται πάντα σε ιατρείο ή σε
            χειρουργική αίθουσα από εξειδικευμένο πλαστικό χειρουργό.
          </p>
          <p>
              Για τη μεταφορά της ενέργειας του LASER στην περιοχή εφαρμογής, δηλαδή στην επιφανειακή υποδόρια στοιβάδα, χρησιμοποιείται μία πολύ λεπτή
              οπτική ίνα, με πάχος μικρότερο από το πάχος μιας τρίχας (200 – 600 μικρά ανάλογα με την περιοχή εφαρμογής). Η αποτελεσματικότητα της θεραπείας
              οφείλεται κυρίως στο μήκος κύματος της ακτινοβολίας του διοδικού LASER που χρησιμοποιείται (1470nm) και απορροφάται από δύο βασικά συστατικά
              του ανθρώπινου σώματος: το νερό και το λίπος.
          </p>
      </div>
      <div className="f-item-media">
        <iframe width="500" height="300" src="https://www.youtube.com/embed/2VnpH39xiUs" title="Laser F.A.S.T." className="video-container" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
        </iframe>
      </div>
    </div>
    <div className="d-flex">
      <div className="f-item-media">
        <img src={lf1} alt="Laser F.A.S.T." className="lf-img" />
      </div>
      <div className="f-item">
          Η αποστειρωμένη οπτική ίνα εισέρχεται εύκολα στο δέρμα, χωρίς τομές με  ή χωρίς τοπική αναισθησία. Προκειμένου η θεραπεία να είναι
          εντελώς ανώδυνη μπορεί να χρησιμοποιηθούν κρύα επιθέματα, κατά τη διάρκεια της εφαρμογής ή ρεύμα κρύου αέρα από ειδική εξωτερική
          συσκευή αν και συνήθως δεν είναι απαραίτητο. Σε κάθε περίπτωση, ο ιατρός σας θα κρίνει αν πρέπει να σας χορηγηθεί τοπική αναισθησία
          για την ανώδυνη εξέλιξη της εφαρμογής.
          <br/><br/>
          Η θεραπεία ολοκληρώνεται χωρίς να χρειαστεί να περιμένετε για την αποθεραπεία ή την επούλωση και φυσικά χωρίς να απαιτείται αποχή
          από τις καθημερινές σας δραστηριότητες.
      </div>
    </div>
    <h4 id='benefits'>ΠΛΕΟΝΕΚΤΗΜΑΤΑ ΤΗΣ ΜΕΘΟΔΟΥ</h4>
    <div>
      <ul>
        <li>Μια Συνεδρία</li>
        <li>Θεραπεία στο ιατρείο, όχι στο χειρουργείο</li>
        <li>Χωρίς τομές</li>
        <li>Χωρίς χρόνο αποθεραπείας</li>
        <li>Άμεσα ορατά αποτελέσματα με απόλυτη ασφάλεια</li>
        <li>Ανώδυνη εφαρμογή με τοπική αναισθησία</li>
        <li>Δυνατότητα συνδυασμού με άλλες θεραπείες</li>
      </ul>
    </div>
    <h4 id='results'>ΑΠΟΤΕΛΕΣΜΑΤΑ ΤΗΣ ΜΕΘΟΔΟΥ</h4>
    <div className="d-flex">
      <div className="f-item-media">
        <img src={lf2} alt="Lase F.A.S.T." className="lf-img" />
      </div>
      <div className="f-item">
          Τα αποτελέσματα είναι πολύ γρήγορα ορατά και συνεχίζουν να βελτιώνονται για αρκετούς μήνες μετά, καθώς το κολλαγόνο συνεχίζει
          να σχηματίζεται στα βαθύτερα στρώματα του χορίου.
          <br/><br/>
          Όπως όλες οι θεραπείες στην Αισθητική Ιατρική έτσι και με την <span className="modal-text-b">Laser F.A.S.T.<sup>®</sup></span>,
          η αποτελεσματικότητα και η διάρκεια του αποτελέσματος διαφοροποιείται από ασθενή σε ασθενή και αν κρίνεται απαραίτητο η θεραπεία
          μπορεί να επαναληφθεί στις πιο επιβαρυμένες περιπτώσεις μετά από 2-3 μήνες, με απόλυτη ασφάλεια. Εχει εφαρμογή και στους άνδρες
          και τις γυναίκες ενώ πέραν της εγκυμοσύνης και της περιόδου θηλασμού, η μέθοδος δεν ενδείκνυται σε άτομα που πάσχουν από νόσους
          του κολλαγόνου ή έχουν σημαντική χαλάρωση για την οποία μία επεμβατική τεχνική αποτελεί την καλύτερη επιλογή.
      </div>
    </div>
    <h4 id='therapyAreas'>ΠΕΡΙΟΧΕΣ ΘΕΡΑΠΕΙΑΣ</h4>
    <div>
      <ul>
        <li>Πρόσωπο (κάτω τριτημόριο, ρινοχειλικές, άνω χείλος, περίγραμμα κάτω γνάθου, σακκούλες ματιών)</li>
        <li>Διπλοσάγωνο</li>
        <li>Λαιμός</li>
        <li>Ντεκολτέ</li>
        <li>Εντοπισμένο λίπος στη μέση, υπογάστριο, γλουτούς</li>
        <li>Σύσφιγξη βραχιόνων, μηρών, υπογάστριού</li>
        <li>Ουλές ακμής</li>
        <li>Χηλοειδή</li>
      </ul>
    </div>
    <p>
        Η κύριος στόχος της θεραπείας <span className="modal-text-b">Laser F.A.S.T.<sup>®</sup></span> είναι η αντιμετώπιση της χαλάρωσης και η σύσφιξη του δέρματος,
        που προκαλείται από την νέο-κολλαγένεση και τις άλλες μεταβολικές λειτουργίες στον εξωκυττάριο χώρο, λόγω της εφαρμογής του LASER. Παράλληλα έχουμε
        αναπτύξει πρωτόκολλα με πιο σύνθετες θεραπείες, συνδυάζοντας διαφορετικές τεχνολογίες για ακόμη καλύτερα αποτελέσματα
    </p>
    <br/><br/>
    <h4 id='examples'>ΑΠΟΤΕΛΕΣΜΑΤΑ</h4>
    <div className="results-flex">
        <img className="fl-img" src={lfa} alt="Ace Athens - Laser F.A.S.T."/>
        <img className="fl-img" src={lfc} alt="Ace Athens - Laser F.A.S.T."/>
        <img className="fl-img" src={lfb} alt="Ace Athens - Laser F.A.S.T."/>
        <img className="fl-img" src={lfd} alt="Ace Athens - Laser F.A.S.T."/>
        <img className="fl-img" src={lfe} alt="Ace Athens - Laser F.A.S.T."/>
    </div>
  </div>
);

export {LaserFast}
