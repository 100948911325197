import React, {useRef} from 'react'
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';
import { HashLink } from 'react-router-hash-link';
import A5a1 from './img/A5a1.jpg';
import A5a2 from './img/A5a2.jpg';
import A5a3 from './img/A5a3.png';
import A5a4 from './img/A5a4.jpg';
import A5a5 from './img/A5a5.png';
import A5a7 from './img/A5a1-gluteal.jpg';
// import A5a6 from './img/need-pic.png';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Limbs = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
        <ScrollToTopOnMount/>
        <h1 ref={topRef}> ΑΚΡΑ <br/><br/> </h1>
        <div className="therapy-links">
            <div className="therapy_bg"> <HashLink to="#lipolysis" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Λιπόλυση</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#liposuction" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Λιποαναρρόφηση</HashLink> </div>
            <div className="therapy_bg"> <HashLink to="#brachioplasty" scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'center'})}>Βραχιο-<br/>πλαστική</HashLink> </div>
        </div>
        <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
        <h3 id='lipolysis'>ΛΙΠΟΛΥΣΗ</h3>
        <p>
          Το λιποκύτταρο είναι ένα μεταβολικά ενεργό κύτταρο που παίζει κεντρικό ρόλο στον έλεγχο της ενεργειακής ισορροπίας του οργανισμού.
          Για την ανάληψη αυτού του ρόλου διαθέτει όλα εκείνα τα ενζυματικά εργαλεία που είναι απαραίτητα για τη σύνθεση ( λιπογένεση ) και αποθήκευση
          των τριγλυκεριδίων καθώς και την κινητοποίηση και απελευθέρωσή τους ως ελεύθερα λιπαρά οξέα ( λιπόλυση ).
        </p>
        <p>
          Ο αρχικός λιπώδης ιστός διαμορφώνεται κατά τον 4ο μήνα της εμβρυικής ζωής από επιθηλιοειδείς λιποβλάστες, ως πρωτόγονος πολύ κενοτοπικός ιστός,
          αποκαλούμενος και φαιό λίπος. Στη συνέχεια προς το τέλος της εμβρυικής ζωής και κατά τους πρώτους μήνες διαμορφώνεται ο ώριμος λιπώδης ιστός,
          ενιαίος και αποκαλούμενος λευκό λίπος. Σήμερα γνωρίζουμε ότι τα ώριμα λιποκύτταρα δεν έχουν δυνατότητα διαίρεσης μετά την εφηβεία και ότι η
          λήψη υπερβολικής τροφής οδηγεί σε πάχυνση αυτών κατά την ενήλικη ζωή.
        </p>
        <p>
          Γνωρίζουμε επίσης ότι το λίπος θεωρείται ως ένα «συνδετικο-ενδοθηλιακό» όργανο με ένα ορμονο-εξαρτώμενο επίπεδο αποθήκευσης λίπους ή
          απελευθέρωσής του που επηρεάζεται από τη διατροφική κατάσταση. Επιπλέον υπόκειται στη δράση των κατεχολαμινών με διαφορετική κατανομή στους
          υποδοχείς της στα δύο φύλα, με αποτέλεσμα λιπαποθήκες όπως η κοιλιακή χώρα για τους άνδρες και η περιοχή των μηρών και γλουτών για τις
          γυναίκες να απαντούν δύσκολα σε δίαιτες ή άσκηση.
        </p>
        <p>
          Η ΛΙΠΟΛΥΣΗ ΣΤΟΧΕΥΕΙ στο ΛΙΠΟΣ που δεν αποκρίνεται στη δίαιτα ή την άσκηση. Δηλαδή στις <b>τοπικές αποθήκες λίπους</b>.
        </p>
        <h4>Α. ΕΝΕΣΙΜΗ ΛΙΠΟΛΥΣΗ </h4>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A5a1} alt="eye" className="img-width-small" />
          </div>
          <div className="flexitem_text_w_picture_wide">
              Μέσω της έγχυσης ειδικών ουσιών ( Δεοξυχολάτης – ουσίας που εκκρίνεται φυσιολογικά στο ήπαρ) γίνεται διάλυση του λίπους και απομάκρυνσή του
              από τον οργανισμό μέσω της συνήθους μεταβολικής διαδικασίας.
          </div>
        </div>
        <p>
          Η έγχυση γίνεται τοπικά στο βαθύ στρώμα του λίπους και ξεκινάει μία φλεγμονώδης διαδικασία που διαρκεί 15 μέρες έως ένα μήνα κατά την οποία το
          λίπος διαλύεται. Μπορεί να γίνει επανάληψη των εγχύσεων μετά 4-6 βδομάδες και ανάλογα με την έκταση του προβλήματος.
        </p>
        <p>
          Κατά τη διάρκεια της λιπόλυσης μπορεί να αισθάνεστε ελαφρό πόνο κατά την ψηλάφηση της περιοχής που έγινε η έγχυση, να παρατηρείτε κοκκινίλα και
          αίσθημα καψίματος.
        </p>
        <p>
          Έχει ένδειξη στο πηγούνι, στο περίγραμμα της κάτω γνάθου, στην κοιλιά, τους μηρούς, στην οπίσθια επιφάνεια των γλουτών και στα πλάγια κοιλιακά
          τοιχώματα.
        </p>
        <div className="sub_motiv_l">
          Το να είσαι χαρούμενη με το σώμα σου δεν σημαίνει ότι χρειάζεται να κάνεις δραματικές αλλαγές.
        </div>
        <div className="sub_motiv_r">
          Αλλά και οι δραματικές αλλαγές είναι σήμερα δυνατές!
        </div>
        <br/><br/><br/><br/>
        <h4>Β. ΕΝΔΟΪΣΤΙΚΗ LASER ΛΙΠΟΛΥΣΗ <br/> &nbsp;&nbsp;&nbsp; Ή ΛΙΠΟΑΝΑΡΡΟΦΗΣΗ με τη χρήση Laser και Υπερβαρικού Οξυγόνου</h4>
        <p>
          Η λιποαναρρόφηση παραμένει μία από τις πιο δημοφιλείς επεμβάσεις παγκοσμίως. Η ευρεία εφαρμογή της ανέπτυξε διάφορες τεχνικές τα τελευταία χρόνια,
          πέραν της παραδοσιακής, για την αφαίρεση του τοπικού λίπους και την ανάπλαση του περιγράμματος του σώματος. Τέτοιες είναι η λιποαναρρόφηση μέσω
          υπερήχων, η μηχανικά υποβοηθούμενη και η χρήση των Laser.
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A5a2} alt="laser" className="img-width-small" />
          </div>
          <div className="flexitem_text_w_picture_wide">
              H εφαρμογή των διοδικών Laser στην λιπόλυση / λιποαναρρόφηση έφερε πραγματικά εντυπωσιακά αποτελέσματα μέσω της καταστροφής του λίπους,
              του περιορισμού της αιμορραγίας κατά την επέμβαση, του ελάχιστου χρόνου ανάρρωσης  και της ρίκνωσης του δέρματος. Έχει άριστα αποτελέσματα
              σε τοπικό λίπος σε περιοχές όπως το πηγούνι, τα γόνατα, την κοιλιά, την έξω επιφάνεια των γλουτών, τη μέση, την έσω και έξω επιφάνεια των
              μηρών και τα γόνατα καθώς και σε ορισμένες μορφές γυναικομαστίας.
          </div>
        </div>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture_wide">
              Γίνεται με τοπική έγχυση αναισθητικού διαλύματος και εκπομπή δέσμης Laser μέσω οπτικής ίνας που εισάγεται στους ιστούς με τη βοήθεια μιας
              ειδικής κάνουλας και καταστροφή των λιποκυττάρων. Ανάλογα με το πάχος του λίπους μπορεί να γίνει σε διαφορετικά επίπεδα, με τελευταίο αυτό,
              κοντά και κάτω από το δέρμα ώστε να επιτευχθεί η ρίκνωσή του . Μπορεί να ολοκληρωθεί με αναρρόφηση του κατεστραμμένου λίπους ή αν είναι
              πολύ μικρή επιφάνεια με παροχέτευση με μασάζ. Στη συνέχεια η περιοχή επιδένεται με κατάλληλους επιδέσμους και ο ασθενής φεύγει περιπατητικός.
              Η επιστροφή στην εργασία του μπορεί να γίνει μια μέρα μετά.
          </div>
          <div className="flexitem_a">
            <img src={A5a3} alt="laser" className="img-width-small" />
          </div>
        </div>
        <p>
          Οι κανονικές δραστηριότητες συστήνεται να επανέρχονται σε φυσιολογικό ρυθμό μετά τη βδομάδα ενώ η άθληση μετά την παρέλευση του μήνα.
        </p>
        <p>
          Ο μηχανισμός έχει αποδειχθεί ότι δεν είναι φωτομηχανικός αλλά συνάρτηση της θερμοκρασίας που αναπτύσσεται και της συνολικής ενέργειας που
          διοχετεύεται σε μια περιοχή του σώματος.
        </p>
        <p>
          Πολλές φορές η ρίκνωση του δέρματος είναι ορατή με το πέρας της χειρουργικής διαδικασίας.
        </p>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A5a4} alt="laser" className="img-width-small" />
          </div>
          <div className="flexitem_text_w_picture_long">
              Στο κέντρο μας, ο συνδυασμός της λιποαναρρόφησης με θεραπεία Υπερβαρικού Οξυγόνου αμέσως μετά την επέμβαση, δίνει ακόμη πιο εντυπωσιακά
              αποτελέσματα αφού περιορίζεται ακόμη περισσότερο το οίδημα των ιστών και ελαττώνεται η περίοδος αποθεραπείας σημαντικά.
          </div>
        </div>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ
          ΕΠΙΠΛΟΚΕΣ ΠΟΥ ΜΠΟΡΟΥΝ ΝΑ ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='liposuction'>ΛΙΠΟΑΝΑΡΡΟΦΗΣΗ <br/> ( Η διαχρονικότητα του κλασσικού )</h3>
        <p> <b>Μέθοδος αδυνατίσματος ή λύση σε τοπικό πρόβλημα πάχους;</b> </p>
        <p>
          Η λιποαναρρόφηση παραμένει μία από τις πιο δημοφιλείς επεμβάσεις παγκοσμίως. Η ευρεία εφαρμογή της ανέπτυξε διάφορες τεχνικές τα τελευταία χρόνια,
          πέραν της παραδοσιακής, για την αφαίρεση του τοπικού λίπους και την ανάπλαση του περιγράμματος του σώματος. Τέτοιες είναι η λιποαναρρόφηση μέσω
          υπερήχων, η μηχανικά υποβοηθούμενη και η χρήση των Laser.
        </p>
        <h4>Σχετικά με τη μέθοδο</h4>
        <div className="sub_flex">
          <div className="flexitem_a">
            <img src={A5a7} alt="eye" className="img-width-medium" />
          </div>
          <div className="flexitem_text_w_picture_wide">
            Από την δεκαετία του 1980 που οι Illouz και Fournier εισήγαγαν τη λιποαναρρόφηση ως μέθοδο για την αντιμετώπιση του τοπικού πάχους, η τεχνική αυτή
            βρήκε πολλές εφαρμογές παγκοσμίως, με πολύ  ικανοποιητικά αποτελέσματα . Για να επιφέρει όμως αυτά τα αποτελέσματα θα πρέπει να ακολουθούνται
            ορισμένα κριτήρια ως προς την επιλογή των περιπτώσεων.  Έτσι ως απόλυτη ένδειξη θεωρείται η τοπική εναπόθεση λίπους στις διάφορες περιοχές του
            σώματος, αφού πρακτικά όλες οι περιοχές μπορούν να υποβληθούν σε λιποαναρρόφηση. Πέραν όμως της τοπικής εναπόθεσης λίπους, θα πρέπει να
            συνεκτιμούνται και άλλοι παράγοντες όπως για παράδειγμα η ηλικία του ασθενούς, η ελαστικότητα και σπαργή του δέρματος, η ύπαρξη ή όχι χαλάρωσης,
            το βάρος του ασθενούς και τυχόν συνοδές νόσοι στο ιστορικό του.  Ως προς τις περιοχές βέβαια, κάποιες φέρουν καλύτερα αποτελέσματα σε σχέση με άλλες
            όπως είναι η περιοχή των γλουτών, τροχαντήρων και μηρών για τις γυναίκες, της κοιλιακής χώρας για τους άνδρες, η περιοχή των έσω γονάτων, της γάμπας,
            των αστραγάλων, του πώγωνος κ.α. Η λιποαναρρόφηση όμως μπορεί να χρησιμοποιηθεί και για την αντιμετώπιση άλλων καταστάσεων όπως η ανδρική μεγαλομαστία,
            το λεμφοίδημα, ευμεγέθη λιπώματα, η λέπτυνση δερμοϋποδόριων κρημνών, η μεταμόσχευση λίπους, όπως και συνδυαστικά με άλλες επεμβάσεις της πλαστικής
            χειρουργικής όπως σε μειωτική μαστών, κοιλιοπλαστική κ.α.
          </div>
        </div>
        <p>
          Η κατανομή του λίπους εξαρτάται κύρια από την κληρονομικότητα και τις διατροφικές συνήθειες του ατόμου. Έτσι η κληρονομικότητα διαμορφώνει τη θέση και
          τον αριθμό των λιποκυττάρων που κατανέμονται στο υποδόριο λίπος, κατά την προ της εφηβείας περίοδο της ζωής. Η διατροφή επίσης μπορεί να επηρεάσει την
          ποσότητα του λίπους που συγκεντρώνεται στο κάθε λιποκύτταρο όχι όμως και τον αριθμό των συνολικών λιποκυττάρων. Έτσι λοιπόν με την λιποαναρρόφηση
          μπορούμε να αναμένουμε «μόνιμα» αποτελέσματα σε μια περιοχή  ως προς τον αριθμό των λιποκυττάρων που θα αφαιρεθούν και που αφορούν τη συγκεκριμένη
          περιοχή και όχι συνολικά τη λιπώδη μάζα του οργανισμού.
        </p>
        <h4>Σχετικά με την επέμβαση</h4>
        <p>
          Η τεχνική της λιποαναρρόφησης μπορεί να εφαρμοστεί τόσο σε βαθύτερα επίπεδα όσο και σε επιφανειακά του υποδόριου λίπους. Η βαρύτητα της εξαρτάται από
          την συνολική ποσότητα λίπους που αφαιρείται από τις διάφορες περιοχές και τη γενική κατάσταση του ενδιαφερόμενου ατόμου και ανάλογα με την έκταση και
          την βαρύτητα, επιλέγεται η τοπική ή η γενική αναισθησία και η ανάγκη μετάγγισης αίματος ( σπάνια ) ή όχι.
        </p>
        <p>
          Κατά την τεχνική της κλασσικής λιποαναρρόφησης γίνεται έγχυση αναισθητικού διαλύματος, μικρές τομές στο δέρμα για την είσοδο της κάνουλας, διάνοιξη
          καναλιών μέσα στο λίπος ακτινωτά και έναρξη της αναρρόφησης. Η επέμβαση ολοκληρώνεται με ομαλοποίηση των ορίων της περιοχής που έχει υποβληθεί στην
          λιποαναρρόφηση με τη χρήση λεπτότερων κανουλών.
        </p>
        <div className="sub_flex">
          <div className="sub_onepic">
            <img src={A5a5} alt="liposuction gear" className="img-width-extra-large" />
          </div>
        </div>
        <p>
          Ο εξοπλισμός που απαιτείται για τη διενέργεια της λιποαναρρόφησης αφορά: κάνουλες διαφόρου διαμετρήματος και μήκους, με άκρη αμβλεία,  ευθεία ή
          κεκαμμένη που φέρει μία η περισσότερες οπές και η οποίες μέσω πλαστικού σωλήνα συνδέονται με συσκευή αναρρόφησης που δημιουργεί αρνητική πίεση και
          συλλέγει το αναρροφούμενο λίπος σε ογκομετρική φιάλη.
        </p>
        <h4>Σχετικά με την μετεγχειρητική πορεία</h4>
        <p>
          Δεν θα πρέπει να ξεχνάμε οτι η λιποαναρρόφηση είναι μια χειρουργική επέμβαση και άρα απαιτείται ο νοσοκομειακός χώρος και εξοπλισμός για την
          κατάλληλη υποστήριξη του ασθενούς, κατά τη διεγχειρητική αλλά και την άμεσα μετεγχειρητική περίοδο και τον έλεγχο τυχόν παρενεργειών, ενώ η επιστροφή
          στις καθημερινές δραστηριότητες ποικίλλει ανάλογα με την περιοχή και την έκταση της επέμβασης.
        </p>
        <p>
          Η διάρκεια παραμονής στο νοσοκομείο εξαρτάται από την ποσότητα του λίπους που αναρροφάται και κυμαίνεται από μερικές ώρες έως ένα βράδυ.
        </p>
        <p>
          Εφαρμόζεται ειδικός ελαστικός επίδεσμος ( κορσές ) που διατηρείται για ένα μήνα περίπου. Η επιστροφή στις κανονικές δραστηριότητες και την άθληση
          συστήνεται να γίνεται μετά τον μήνα και οπωσδήποτε εξαρτάται από την έκταση και τη θέση της επέμβασης.
        </p>
        <p>
          Τις πρώτες μέρες μπορεί να παρατηρηθεί οίδημα στην περιοχή και μελανιές που υποχωρούν σταδιακά. Το τελικό αποτέλεσμα της μεθόδου φαίνεται μετά 6 μήνες
          από την επέμβαση.
        </p>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ
          ΕΠΙΠΛΟΚΕΣ ΠΟΥ ΜΠΟΡΟΥΝ ΝΑ ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
        <h3 id='brachioplasty'>ΒΡΑΧΙΟΠΛΑΣΤΙΚΗ (BRACHIOPLASTY)</h3>
        <p>
          Τυχόν δυσμορφία στην περιοχή των βραχιόνων δημιουργεί έντονα προβλήματα εμφάνισης, ιδιαίτερα τους καλοκαιρινούς μήνες που ο ρουχισμός είναι πιο ελαφρύς.
        </p>
        <div className="sub_flex">
          <div className="flexitem_text_w_picture_missing">
              Τα συνήθη προβλήματα δυσμορφίας στην περιοχή των βραχιόνων  είναι: άθροιση τοπικού λίπους, κληρονομική διαμόρφωση του συνόλου των βραχιόνων και
              έντονη χαλάρωση δέρματος λόγω ηλικίας ή μεγάλης απώλειας βάρους. Όταν το αίτιο αφορά μεγάλη απώλεια βάρους, τότε μπορεί να συνυπάρχουν και άλλες
              μορφές δυσμορφίας και έντονης χαλάρωσης στην περιοχή του θώρακα, της κοιλιάς και των μηρών.
          </div>
        </div>
        <ul>
          Η διαβάθμιση των επεμβάσεων είναι
          <li>
            σε απλή άθροιση λίπους και καλή ελαστικότητα δέρματος, απλή λιποαναρρόφηση. Σε περιπτώσεις όμως, όπου η ελαστικότητα είναι αμφίβολη η
            λιποαναρρόφηση μπορεί να γίνει με Laser.
          </li>
          <li>
            σε ήπια χαλάρωση του δέρματος, μπορεί να γίνει δερμολιπεκτομή με μικρή τομή που κρύβεται στο επίπεδο της μασχάλης και μπορεί να συνδυαστεί και
            με λιποαναρρόφηση.
          </li>
          <li>
            σε μεγαλύτερου όμως βαθμού χαλάρωση δέρματος, η επέμβαση γίνεται με το σχεδιασμό για μεγαλύτερης έκτασης εκτομή του δέρματος που περισσεύει και
            με τομή που εκτείνεται από το επίπεδο της μασχάλης μέχρι τον αγκώνα. Σχεδιάζεται έτσι ώστε να «κρύβεται» στο σημείο μετάπτωσης του δέρματος της οπίσθιας
            επιφάνειας που είναι σκουρότερο σε αυτό της έσω πλευράς που συνήθως είναι ανοικτότερου χρώματος. Παρόλα αυτά η εναπομείνουσα ουλή είναι πλέον μόνιμη.
            Γι αυτό και συστήνεται ως επέμβαση μόνο στις περιπτώσεις που έχει απόλυτη ένδειξη, γεγονός που θα πρέπει να συζητηθεί αναλυτικά προεγχειρητικά με τον χειρουργό.
          </li>
        </ul>
        <p>
          Η επέμβαση γίνεται με γενική αναισθησία και η διάρκεια παραμονής στο Νοσοκομείο είναι λίγες ώρες μετά το χειρουργείο. Μετά την επέμβαση συστήνεται η εφαρμογή
          ενός ειδικού ελαστικού επιδέσμου επί ένα μήνα περίπου.
        </p>
        <p>
          <b>ΟΙ ΑΙΣΘΗΤΙΚΕΣ ΧΕΙΡΟΥΡΓΙΚΕΣ ΕΠΕΜΒΑΣΕΙΣ ΘΕΩΡΟΥΝΤΑΙ ΙΑΤΡΙΚΕΣ ΠΡΑΞΕΙΣ ΚΑΙ ΕΝΕΧΟΥΝ ΤΟΝ ΚΙΝΔΥΝΟ ΕΠΙΠΛΟΚΩΝ, ΟΠΩΣ ΟΛΕΣ ΟΙ ΧΕΙΡΟΥΡΓΙΚΕΣ ΠΡΑΞΕΙΣ. ΣΥΖΗΤΗΣΤΕ ΜΕ ΤΟΝ ΧΕΙΡΟΥΡΓΟ ΣΑΣ ΓΙΑ ΤΙΣ ΤΥΧΟΝ
          ΕΠΙΠΛΟΚΕΣ ΠΟΥ ΜΠΟΡΟΥΝ ΝΑ ΕΠΙΣΥΜΒΟΥΝ.</b>
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
      </div>
)};

export default Limbs
