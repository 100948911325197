import React from 'react';
import kp from './kp1.jpg';

const Phil = ({children}) => (
  <div className="pop_page">
    <div className="sub_flex_new">
      <div className="flexitem_kp">
        <img src={kp} alt="mugshot" className="kp-mugshot" />
      </div>
      <div className="flexitem_text">
          Δεν είναι πάντα ξεκάθαρο πότε ένας άνθρωπος αποφασίζει για τον επαγγελματικό του προσανατολισμό και ποια είναι τα χαρακτηριστικά εκείνα που τον οδηγούν σε συγκεκριμένες
          επιλογές. Από τα μαθητικά μου όμως χρόνια στην Πάτρα την δεκαετία του 1970 και με την ευαισθητοποίησή μου από τις αναφορές που είχα σ’ ένα σχολείο πρωτοποριακό για την
          εποχή του, όπως ήταν το <span className="modal-text-b">Πειραματικό Σχολείο του Πανεπιστημίου Πατρών</span>, αγάπησα τον άνθρωπο, τον πολιτισμό και τις τέχνες και καταστάλαξα στον προσανατολισμό μου για την
          Ιατρική από πολύ νωρίς. Σπουδάζοντας στην <span className="modal-text-b">Ιατρική Σχολή Αθηνών</span> από όπου και αποφοίτησα το 1987 ενίσχυσα τις πεποιθήσεις μου ότι η Ιατρική και ακόμη περισσότερο η Χειρουργική
          πέρα από επιστήμη ενέχει και χαρακτηριστικά άλλων τεχνών  όπως η  γλυπτική και η  ζωγραφική, η μουσική ή η λογοτεχνία. Η αγάπη μου για τη Χειρουργική αλλά και η εκμάθηση
          μουσικών οργάνων  ή η ενασχόλησή μου με τις τέχνες γενικότερα, μου ξεκαθάρισαν την ιδανικότερη για μένα επιλογή, αυτή της Πλαστικής Χειρουργικής, όπου διατηρεί σε αρμονία
          τόσο τη λειτουργική όσο και την αισθητική αποκατάσταση σε προβλήματα υγείας που αφορούν πάσχοντες ή και μη, συνανθρώπους μας. Το δέρμα, το μεγαλύτερο όργανο του ανθρώπινου
          σώματος, αποτελεί τον φραγμό του ανθρώπινου οργανισμού προς το εξωτερικό περιβάλλον και το αντικείμενο αντίληψης πολλών αισθήσεών μας όπως της όρασης ή της αφής κι έτσι η
          οποιαδήποτε παρέμβασή μας επάνω του, αφήνει ανεξίτηλα τα σημάδια της τόσο στον ίδιο όσο και στους γύρω.
      </div>
    </div>
    <div>
      <p>
        <span className="modal-text-b">Τον τίτλο της ειδικότητας της Πλαστικής Χειρουργικής</span> τον πήρα στην Ελλάδα κατόπιν επιτυχών εξετάσεων το 1998 και έκτοτε εργάζομαι
        ανελλιπώς ως Πλαστικός Χειρουργός στην Αθήνα, ως <span className="modal-text-b">επιμελητής στο Ογκολογικό Νοσοκομείο ΙΚΑ «Γ.Γεννηματάς»</span> από το 1998 έως το 2011 και ως
        <span className="modal-text-b"> επιμελητής Α΄ στο Γενικό Ογκολογικό Νοσοκομείο Κηφισιάς «Αγ. Ανάργυροι»</span> από το 2011 έως το 2016, ενώ παράλληλα διατηρούσα και
        <span className="modal-text-b"> ιδιωτικό ιατρείο</span> . Από το 2015 έως τα τέλη του 2017 εργάστηκα ως Διευθυντής του Τμήματος Πλαστικής Χειρουργικής στο ιδιωτικό Νοσοκομείο
        του Ομίλου Mouwasat στο Dammam της Σαουδικής Αραβίας, ενώ από τις αρχές του 2018 είμαι επιστημονικός Διευθυντής της ιατρικής εταιρείας Ace Athens στην Αθήνα και συνεργάζομαι
        με το Metropolitan General στο Χολαργό, το Κέντρο Poseidonia Healthcare στην Κύπρο και τον Όμιλο Alhokail Polyclinics στη Σαουδική Αραβία και το Μπαχρέιν. Όταν φυσικά αγαπάς
        αυτό που κάνεις, έχεις έναν παραπάνω λόγο να εξελίσσεις συνεχώς τον τομέα σου και τις γνώσεις σου πάνω στο αντικείμενο και γι’ αυτό τον λόγο έχω παρακολουθήσει και
        παρακολουθώ σειρά εκπαιδευτικών σεμιναρίων τόσο στην Ελλάδα όσο και στο εξωτερικό, ενώ την μετεκπαίδευσή μου την έκανα στο <span className="modal-text-b">Πανεπιστημιακό
        Νοσοκομείο της Γάνδης του Βελγίου</span>, με έμφαση πάνω στην αποκατάσταση του μαστού μετά από μαστεκτομή.
      </p>
      <p>
        Ακόμη και σήμερα μετά από όλα αυτά τα χρόνια άσκησης της πλαστικής χειρουργικής, θεωρώ ότι ο <span className="modal-text-b">ολοκληρωμένος ιατρός</span> θα πρέπει να έρχεται
        συνεχώς σε επαφή με την ιατρική στην ολότητά της και να αποκωδικοποιεί την έκφραση της ασθένειας και την αντιμετώπισή της ανεξάρτητα από φύλο, φυλή, εθνικότητα και ιδιαίτερα
        ατομικά χαρακτηριστικά. Έτσι είχα την τιμή να είμαι ένας από τους ιδρυτές του ελληνικού τμήματος των <span className="modal-text-b">Γιατρών Χωρίς Σύνορα</span> το 1990 (στην
        οποία διετέλεσα και Πρόεδρος από το 2000-2005) και να βιώσω τις διαφορετικές εκφράσεις του ανθρώπινου πόνου σε διάφορες αποστολές όπου και συμμετείχα όπως στο Ιράκ, στον πόλεμο
        της π. Γιουγκοσλαβίας, στην Παλαιστίνη, τη Ζάμπια, το Μαλάουι, το Μπουρούντι, τη Ρουάντα, το Ζαΐρ, την Αιθιοπία,  την Γεωργία και το Πακιστάν-Αφγανιστάν. Η αντίδραση των
        ανθρώπων σε μια μεγάλη φυσική καταστροφή, στον πόλεμο, στην απόλυτη φτώχεια και την κατάρρευση των συστημάτων υγείας είναι η ίδια και είναι αυτή της αναζήτησης της αξιοπρέπειας,
        του αισθήματος της ασφάλειας και του σεβασμού στην ανθρώπινη ζωή. Η επί δεκαπέντε χρόνια συνεχής επαφή με τις αρχές και τις πρακτικές της ανθρωπιστικής δράσης με ώθησαν να κάνω
        τις μεταπτυχιακές μου σπουδές πάνω στην Ιατρική Καταστροφών και από το 2004 είμαι κάτοχος, μετά από εξετάσεις, του <span className="modal-text-b">Ευρωπαϊκού Μάστερ της Ιατρικής
        Καταστροφών</span> από τη συνεργασία δύο Πανεπιστημίων, αυτών των Βρυξελλών και του Πιεμόντ της Ιταλίας. Η εμπειρία μου αλλά και το θεωρητικό πλέον υπόβαθρο στον τομέα αυτό
        μου παρείχαν τα εφόδια, ώστε να είμαι εκπαιδευτής σε προγράμματα του ΙΝΕΠ και του ΕΚΔΔ σχετικά με ζητήματα πολιτικής προστασίας και ανθρωπιστικής ιατρικής, ενώ την τριετία 2011-2013, διετέλεσα επιστημονικά
        Υπεύθυνος της Ενότητας Ιατρικής Καταστροφών στο ΜΠΣ του ΕΚΠΑ « Διεθνής Ιατρική- Διαχείριση κρίσεων».
      </p>
      <p>
        <span className="modal-text-b">Το Πιστεύω μου</span> λοιπόν πάνω στην καθημερινή άσκηση της Ιατρικής Ειδικότητας είναι ότι η <span className="modal-text-b">Πλαστική
        Χειρουργική</span> είναι μία <span className="modal-text-b">Ύψιστη Τέχνη</span>, που συνδυάζει την Επιστημονική σκέψη και γνώση με την Καλλιτεχνική έκφραση της αρμονίας,
        αναλογίας και συμμετρίας στο ανθρώπινο σώμα, όπως αυτές έχουν περιγραφεί από τους πρώτους Πυθαγόρειους και τον «Κανόνα» του Πολύκλειτου έως τον άνθρωπο του Βιτρούβιου και
        τη μελέτη του Λεονάρντο Ντα Βίντσι. Στη σύγχρονη δε εφαρμογή της, θα πρέπει να διέπεται από αρχές όπως ο σεβασμός των ιστών, η τήρηση των αναλογιών του σώματος και η εφαρμογή
        τεχνικών που ακολουθούν  τις σύγχρονες διεθνείς βιβλιογραφικές αναφορές και τους σύγχρονους κανόνες ασφάλειας και ελέγχου των υλικών της ιατρο-βιοτεχνολογίας.
      </p>
    </div>
    <div>
          <h2>  ΒΙΟΓΡΑΦΙΚΟ ΣΗΜΕΙΩΜΑ </h2>
          <p>
            Κωνσταντίνος  Παπαιωάννου, MD, MSc <br/>
            Πλαστικός χειρουργός
          </p>
          <p>
            Λ. Κηφισίας 27Α, Αθήνα, 11523 <br/>
            ( Επιστημονικός Διευθυντής AceAthens – Aesthetics Centre – Πλαστική, Επανορθωτική και Αισθητική Χειρουργική )
          </p>
          <ul>
            <li>Τηλ : 210 6471066</li>
            <li>Κιν : 6944 603863</li>
            <li>Email: papa_kos@otenet.gr</li>
            <li>Internet site: www.aceathens.gr</li>
            <li>Ημερομηνία γέννησης:  07/04/1963</li>
          </ul>
          <h3>ΕΚΠΑΙΔΕΥΣΗ</h3>
          <ol>
            <li>Απόφοιτος Πειραματικού Σχολείου Πανεπιστημίου Πατρών ( 1981)</li>
            <li>Ιατρική Σχολή Αθηνών – Εθνικό και Καποδιστριακό Πανεπιστήμιο Αθηνών ( 1987)</li>
            <li>Τίτλος Ιατρικής ειδικότητας Πλαστικής, Επανορθωτικής και Αισθητικής Χειρουργικής ( 1998 ) – Νοσοκομεία : Ογκολογικό Νοσοκομείο ΙΚΑ « Γ.Γεννηματάς», ΚΑΤ, Γεν. Κρατικό Αθηνών</li>
            <li>Γλώσσες : Ελληνικά( Μητρική ) – Αγγλικά ( άριστα ) – Γαλλικά και Ισπανικά ( μέτρια )</li>
          </ol>
          <h3>ΜΕΤΑΠΤΥΧΙΑΚΕΣ ΣΠΟΥΔΕΣ – ΜΕΤΕΚΠΑΙΔΕΥΣΗ στο ΕΞΩΤΕΡΙΚΟ</h3>
          <ol>
            <li>«Stem cells and regenerative Medicine» – Αγγλόφωνο Μεταπτυχιακό πρόγραμμα σπουδών – Τμήμα μοριακής Βιολογίας- ΕΚΠΑ ( υπό εξέλιξη)</li>
            <li>Κάτοχος Ευρωπαϊκού Master Ιατρικής Καταστροφών ( Παν. Piedmont – Brussels) – 2003</li>
            <li>Αποκατάσταση μαστού – Πανεπιστημιακό Νοσοκομείο Γάνδης Βελγίου ( 2000 )</li>
            <li>CEMEC – Ευρωπαϊκό Κέντρο Ιατρικής Καταστροφών – ΣΑΝ ΜΑΡΙΝΟ 10-24 Μαίου 2003</li>
            <li>18th International free flaps & island flaps ( Workshop- άσκηση σε πτώμα ) , Λιέγη Βελγίου 23-26 Νοεμβρίου 2000</li>
            <li>«International practical course in Microsurgery» , Timisoara, Romania – 3-6 /4/00</li>
            <li>Εκπαιδευτικό σεμινάριο με άσκηση « Αποκατάσταση μαστού με αυτόλογους ιστούς, κρημνοί βασιζόμενοι σε διατιτρώσες, άσκηση σε πτώμα » 8-13/11/ 99, Μόναχο , Γερμανία</li>
            <li>Πιστοποίηση στην χρήση εξειδικευμένης τεχνολογίας Laser</li>
            <li>Laser physics and clinical applications, Clinical Training course, Israel, 25-29 April 1999</li>
            <li>Τρίμηνη άσκηση στη Μικροχειρουργική, στο εργαστήριο Μικροχειρουργικής στο Κ.Α.Τ ( 1998 )</li>
            <li>Παρακολούθηση του ειδικού σεμιναρίου αντιμετώπισης καταστάσεων κρίσης «Populations en Danger (PSP)» για συντονιστές , EPICENTRE – Paris, 4 εβδομάδες , Οκτώβριος 1992</li>
          </ol>
          <h3>ΤΟΜΕΙΣ ΕΞΕΙΔΙΚΕΥΣΗΣ</h3>
          <ul>
            <span className="modal-text-b">Χειρουργική αποκατάσταση</span>
            <li>Μαστού μετά μαστεκτομή</li>
            <li>Αισθητικών δυσμορφιών</li>
            <li>Τραύματος</li>
            <li>Συγγενών ανωμαλιών</li>
            <li>Καλοήθων και κακοήθων όγκων μαλακών μορίων</li>
            <li>Εγκαύματος</li>
          </ul>
          <ul>
            <span className="modal-text-b">Εφαρμογές Laser</span>
            <li>Καλοήθων βλαβών δέρματος</li>
            <li>Αγγειακών βλαβών δέρματος</li>
            <li>Μελαγχρωστικών βλαβών δέρματος</li>
            <li>Αισθητικών δυσμορφιών δέρματος</li>
          </ul>
          <ul>
            <span className="modal-text-b">Μικροχειρουργική τεχνική αποκατάστασης ελλειμμάτων μαλακών μορίων</span>
            <span>Master στην Ιατρική Καταστροφών – Ευρωπαϊκό Κέντρο Ιατρικής Καταστροφών (San Marino)</span>
            <li>Διενέργεια διερευνητικών αποστολών εκτίμησης ιατρικών αναγκών σε προσφυγικούς πληθυσμούς και ιατρικές δομές ( Κ.Υ και Νοσοκομεία)</li>
            <li>Σχεδιασμός-οργάνωση και παρακολούθηση ιατρικών προγραμμάτων σε επείγουσες αλλά και χρόνιες καταστάσεις</li>
            <li>Διαχείριση ανθρώπινου δυναμικού</li>
            <li>Διαχείριση υλικοτεχνικού υλικού</li>
            <li>Επιμόρφωση σε θέματα δημόσιας υγείας και στατιστικής ανάλυσης δεδομένων</li>
            <li>Ειδική θεματολογία σε επιδημιολογία, υγιεινή νερού, εμβολιασμούς, τροπικά νοσήματα</li>
          </ul>
          <h3>ΕΠΑΓΓΕΛΜΑΤΙΚΗ ΕΜΠΕΙΡΙΑ</h3>
          <p>Από τέλος 2017 μόνιμη συνεργασία με Νοσοκομεία ΙΑΣΩ General και ΡΕΑ</p>
          <p>11/2017 – Επιστημονικός Διευθυντής ACE Athens – Aesthetics Center</p>
          <p>2015 -2017 : Διευθυντής Τμήματος Πλαστικής Χειρουργικής , Mouwasat Dammam Hospital, Σαουδική Αραβία</p>
          <p>2011 – 2015: Επιμηλητής Α’ , Τμήμα Πλαστικής Χειρουργικής , Γενικό και Ογκολογικό Νοσοκομείο Κηφισιάς «Αγ. Ανάργυροι»</p>
          <p>1999 – 2011: Επιμελητής , Τμήμα Πλαστικής Χειρουργικής , Ογκολογικό Νοσοκομείο ΙΚΑ «Γ.Γεννηματάς»</p>
          <p>1999-2016 : Ιδιωτικό ιατρείο</p>
          <p>2004: Εκπαιδευτής ΙΝΕΠ ( Ινστιτούτο Επιμόρφωσης- Εθνικό Κέντρο Δημόσιας Διοίκησης ) με αριθμό Μητρώου 7434 / 28.5.04</p>
          <p>1989-1992: Άσκηση Ειδικότητας Γενικής Χειρουργικής στα πλαίσια της ειδικότητας της Πλαστικής Χειρουργικής  ( Νοσοκομείο ΠΑΜΜΑΚΑΡΙΣΤΟΣ και 414 ΓΣΝ Ξάνθης )</p>
          <p>1994-1998 : Άσκηση Ειδικότητας Πλαστικής Χειρουργικής ( ολοκλήρωση ειδικότητας Ογκολογικό Νοσοκομείο ΙΚΑ « Γ.Γεννηματάς», ΚΑΤ, Γεν. Κρατικό Αθηνών )</p>
          <h3>ΔΙΔΑΚΤΙΚΟ ΕΡΓΟ</h3>
          <ol>
            <li>Τακτικός Συνεργάτης του μεταπτυχιακού προγράμματος « Διεθνής Ιατρική- Διαχείριση κρίσεων υγείας» - Τμήμα ΙΑΤΡΙΚΉς-Σχολή επιστημών υγείας Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών  ( 2011-2013 )</li>
            <li>Τακτικός Συνεργάτης του μεταπτυχιακού προγράμματος “ Health crisis management” – ΕΚΠΑ ( 2015 )</li>
            <li>Εισηγητής σε μεταπτυχιακούς φοιτητές της Εθνικής Σχολής Δημόσιας Διοίκησης με θέμα «ΔΙΑΧΕΙΡΙΣΗ ΚΙΝΔΥΝΟΥ και ΚΑΤΑΣΤΡΟΦΩΝ» - 18 ώρες ( Μάιος- Ιούνιος 2011 )</li>
            <li>Εισηγητής σε μεταπτυχιακούς φοιτητές της Εθνικής Σχολής Δημόσιας Διοίκησης με θέμα «Αποκέντρωση και Πρόσβαση Μεταναστών στις Υπηρεσίες Υγείας» - 30 ώρες ( Μάιος –Ιούνιος 2011 )</li>
            <li>Εισηγητής σε μεταπτυχιακούς φοιτητές του μεταπτυχιακού προγράμματος « Διεθνής Ιατρική- Διαχείριση κρίσεων υγείας» - Τμήμα ΙΑΤΡΙΚHΣ -Σχολή επιστημών υγείας Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών 2010-2011. 12 ώρες με θέματα ( 8/3/11, 15/3/11, 22/3/11 )
              <br/> - Γενικός και υγειονομικός σχεδιασμός αντιμετώπισης κρίσεων
              <br/> - Προ-νοσοκομειακή διαχείριση θυμάτων
              <br/> - Η διαλογή ασθενών ( Triage )
              <br/> - Νοσοκομειακή διαχείριση θυμάτων
              <br/> - Command and Control
              <br/> - Ασφάλεια και προστασία
            </li>
            <li>Εισηγητής στους σπουδαστές του Εθνικού Κέντρου Δημόσιας Διοίκησης  την περίοδο 2009-2010 με θέμα  «επιδημίες» - 14 ώρες</li>
            <li>Εκπαιδευτής σε πρόγραμμα ειδικευομένων του Ογκολογικού Νοσοκομείου ΙΚΑ «Γ.ΓΕΝΝΗΜΑΤΑΣ» στο Νεκροτομείο Αθήνας. Εκπ. έτος 2009-2010</li>
            <li>Διάλεξη με θέμα «Κρημνοί-μοσχεύματα στην πλαστική χειρουργική» στο εκπαιδευτικό πρόγραμμα της Β Ορθοπαιδικής Πανεπιστημιακής Κλινικής, Νοσοκομείο Αγ. Ολγα, 7-2-2007</li>
            <li>Εκπαιδευτής στα προγράμματα των ΚΕΚ , για την Πολιτική Προστασία και Οργάνωση ΤΕΠ σε μαζικές καταστροφές με τις θεματικές « Ο ρόλος του Νοσοκομείου σε μαζικές καταστροφές», «Σενάριο ασφαλούς εγκατάλειψης Νοσοκομείου», και «Διαλογή και αντιμετώπιση πολυ-τραυματία»
              <br/>Επιστημονικός υπεύθυνος σε δύο προγράμματα εξ’ αυτών ( Αλεξανδρούπολης και ΚΑΤ)
              <br/> - Χαλκίδα , 17 ώρες, 16-18 Μαρτίου 2006
              <br/> - Αλεξανδρούπολη, 35 ώρες, 10-15 Απριλίου 2006
              <br/> - ΚΑΤ , 17 ώρες, 27-29 Απριλίου 2006-11-23
              <br/> - ΚΑΤ, 25 ώρες, 21, 23-25 Μαΐου 2006
              <br/> - Ηράκλειο, 17 ώρες, 1-3 Ιουνίου 2006
              <br/> - Χίος, 17 ώρες, 15-17 Ιουνίου 2006
              <br/> - Αλεξανδρούπολη, 17 ώρες, 6-8 Ιουλίου 2006
            </li>
            <li>Εισηγητής σε ημερίδες του Ινστιτούτου Επιμόρφωσης  στις 13 Περιφέρειες της χώρας με τίτλο «Αντιμετώπιση φυσικών καταστροφών και περιφερειακές δράσεις». Θέμα εισήγησης «Διαχείριση τραυματιών σε καταστάσεις κρίσης»</li>
            <li>Εκπαιδευτής στα προγράμματα διαρκούς εκπαίδευσης ενηλίκων, Εθνικό κέντρο Δημόσιας Διοίκησης, Διημερίδα με τίτλο «Αντιμετώπιση Φυσικών Καταστροφών και Δημόσια Διοίκηση». Τίτλος παρουσίασης «Σχεδιασμός και υλοποίηση προγράμματος αντιμετώπισης κρίσεων»
              <br/> - Αθήνα, 20-21 Οκτωβρίου 2003
              <br/> - Θεσσαλονίκη, 3-4 Νοεμβρίου 2003
            </li>
            <li>Υπεύθυνος εκπαιδευτικού προγράμματος ειδικευομένων στην Κλινική Πλαστικής, Επανορθωτικής Χειρουργικής-Ογκολ. Νος. ΙΚΑ ¨Γ.Γεννηματάς¨» την περίοδο 2000-2001</li>
            <li>Εκπαιδευτής στο θεωρητικό και πρακτικό μέρος , στο 4ο μετεκπαιδευτικό Σεμινάριο Πλαστικής Χειρουργικής, Εταιρεία Πλαστικής, Επανορθωτικής  Χειρουργικής, 4-12-99, ΙΑΣΩ ( Φυσική των Laser και αλληλεπιδράσεις στους ιστούς, κλινική επίδειξη περιστατικών )</li>
            <li>Εκπαιδευτής στο 3ο μετεκπαιδευτικό Σεμινάριο Πλαστικής Χειρουργικής, Εταιρεία Πλαστικής, Επανορθωτικής  Χειρουργικής, 11-10-99, ΙΑΣΩ ( β μέρος: Νd:YAG Laser- Θεραπεία αγγειακών βλαβών )</li>
            <li>Εκπαιδευτής στο 1ο μετεκπαιδευτικό Σεμινάριο Πλαστικής Χειρουργικής, Εταιρεία Πλαστικής, Επανορθωτικής  Χειρουργικής, 13 –3-99, ΙΑΣΩ ( β μέρος: Vasculight- αγγειακές βλάβες )</li>
            <li>Εκπαιδευτής στο ISAPS  Laser workshop IASO – Σεπτέμβριος 1998</li>
            <li>Εκπαιδευτής στο  2nd Laser Workshop « Silk-Touch with Feather mode Skin resurfacing and Hair Transplantation», Oγκολ. Νοσ.ΙΚΑ, Aθήνα 17-18/4/1997</li>
            <li>Εκπαιδευτής στο 1ο Laser Workshop « Silk-Touch Skin resurfacing and Hair Transplantation», Oγκολ. Νοσ.ΙΚΑ, AΘήνα 30-31/5/1996</li>
          </ol>
          <p>Συμμετοχή σε πλήθος συνεδρίων, σεμιναρίων και εργαστηρίων στην Ελλάδα και το εξωτερικό και μεγάλος αριθμός ανακοινώσεων και δημοσιεύσεων. Συμμετοχή σε στρογγυλές επιστημονικές τράπεζες ως ομιλητής- εισηγητής</p>
          <h3>ΔΙΕΘΝΗΣ ΕΠΑΓΓΕΛΜΑΤΙΚΗ ΕΜΠΕΙΡΙΑ</h3>
          <p>Ιδρυτικό μέλος των Γιατρών Χωρίς Σύνορα Ελλάδος ( ΓΧΣ ) - ( 1990 )</p>
          <p>Πρόεδρος ΔΣ ΓΧΣ ( 2001-2005 )</p>
          <p>Γεν Γρμματέας ΔΣ ΓΧΣ ( 1992-2000 )</p>
          <p>Συμμετοχή σε πλήθος ανθρωπιστικών αποστολών με το ελληνικό και ολλανδικό τμήμα, όπως στο Κουρδιστάν, στη Γιουγκοσλαβία, στην Αλβανία,  στη Ζάμπια, στο Μαλάουι, στη Ρουάντα, Μπουρούντι και Κονγκό, στην Αιθιοπία, στην Παλαιστίνη, στη Γεωργία και στο Αφγανιστάν</p>
          <br/>
          <h3>ΜΕΛΟΣ ΕΤΑΙΡΕΙΩΝ</h3>
          <p>Μέλος ΙΑΤΡΙΚOY ΣΥΛΛΟΓΟY ΑΘΗΝΩΝ</p>
          <p>Μέλος ΕΛΛΗΝΙΚΗΣ ΕΤΑΙΡΕΙΑΣ ΠΛΑΣΤΙΚΗΣ, ΕΠΑΝΟΡΘΩΤΙΚΗΣ και ΑΙΣΘΗΤΙΚΗΣ ΧΕΙΡΟΥΡΓΙΚΗΣ</p>
          <p>Μέλος ISAPS ( International Society of Aesthetic and Plastic Surgery )</p>
          <p>Μέλος ΙΑΤΡΙΚΟΥ ΣΥΛΛΟΓΟΥ ΣΑΟΥΔΙΚΗΣ ΑΡΑΒΙΑΣ</p>
          <p>Μέλος ΙΑΤΡΙΚΟΥ ΣΥΛΛΟΓΟΥ ΚΥΠΡΟΥ</p>
          <p>Μέλος Γιατρών Χωρίς Σύνορα Ελλάδας</p>
    </div>
  </div>
);

export {Phil}
