import React, {useRef} from 'react'
import {Popform} from './popform';
// import scrollToComponent from 'react-scroll-to-component';
import FontAwesome from 'react-fontawesome';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}
const scrollUpToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-400)

const Repairmastos = () => {
    const topRef = useRef(null);
    const executeScroll = () => scrollUpToRef(topRef);
    return (
      <div className="sub_page">
      <ScrollToTopOnMount/>
        <h1 ref={topRef}> ΑΠΟΚΑΤΑΣΤΑΣΗ ΜΑΣΤΟΥ ΜΕΤΑ ΜΑΣΤΕΚΤΟΜΗ </h1>
        <Popform title={`Ace Athens`} subtitle={'Ρωτήστε την'} openFormButton={'Ρωτήστε μας'} />
        <p>
          Από την πρώτη εφαρμογή σιλικονούχων ενθεμάτων μαστού το 1963, οι μέθοδοι αποκατάστασης του μαστού μετά μαστεκτομή για ογκολογικούς λόγους  είδαν τεράστια εξέλιξη κι έτσι σήμερα
          πλέον αποτελούν ένα από τα σημαντικότερα σύγχρονα επιτεύγματα της Πλαστικής Χειρουργικής. Ψυχολογικοί και κοινωνικοί είναι οι κυριότεροι λόγοι που αύξησαν το ενδιαφέρον για την
          ανάπτυξη των μεθόδων αποκατάστασης του μαστού αφού πέρα από τους πρακτικούς λόγους ντυσίματος της γυναίκας και της διευκόλυνσής της στις καθημερινές κοινωνικές δραστηριότητες της,
          συμβάλλει σημαντικά και στην ψυχολογική υποστήριξη της, αφού υποβάλλεται στην τραυματική εμπειρία του ακρωτηριασμού ενός μέρους του σώματός του. Ο μαστός βέβαια που αποκαθίσταται
          αποτελεί πάντα ένα υποκατάστατο του πραγματικού οργάνου, χωρίς καμία λειτουργική οντότητα, πέραν αυτής της εμφάνισης.
        </p>
        <p>
          Η κύρια ένδειξη για την αποκατάσταση του μαστού είναι όταν μετά την μαστεκτομή δεν συντρέχει περαιτέρω ογκολογικό πρόβλημα σε εξέλιξη και η τυχόν χημειοθεραπεία ή/και
          ακτινοθεραπεία έχουν ολοκληρωθεί. Ο δε χρόνος αποκατάστασης διακρίνεται στην άμεση αποκατάσταση που επισυμβαίνει στον ίδιο χρόνο με την μαστεκτομή και στην όψιμη αποκατάσταση σε
          χρόνο μεταγενέστερο της ογκολογικής επέμβασης. Η επιλογή του χρόνου αποκατάστασης εξαρτάται κύρια από τις ογκολογικές παραμέτρους που εμφανίζει το κάθε άτομο ξεχωριστά.
        </p>
        <ol>
          Οι κατηγορίες των επεμβάσεων αποκατάστασης που εφαρμόζονται είναι δύο:
          <li>επεμβάσεις με τη χρήση ενθεμάτων</li>
          <li>επεμβάσεις με τη χρήση αυτόλογων ιστών</li>
        </ol>
        <h3>Αποκατάσταση με ενθέματα</h3>
        <p>
           Οι καλύτερες υποψήφιες ασθενείς για την αποκατάσταση με ενθέματα είναι αυτές που έχουν μέτριο (&lt;750γρ) και με μικρή πτώση τον αντίθετο μαστό από την μαστεκτομή καθώς και
           αρκετό και υγιή μαλακό ιστό για να καλύψει επαρκώς το ένθεμα στην μαστεκτομηθείσα περιοχή.  Στην πλευρά όμως της μαστεκτομής σπάνια υπάρχει επάρκεια δέρματος για να τοποθετηθεί
           το ένθεμα σιλικόνης και γι’ αυτό τοποθετείται κάτω από τον μείζονα θωρακικό μυ, αρχικά ένας ειδικός διατατήρας ιστών που διατείνεται σταδιακά με φυσιολογικό ορρό μέσω υποδόριας
           βαλβίδας. Αφού επιτευχθεί η διάταση του δέρματος που θέλουμε, μετά από έξι μήνες προγραμματίζεται η δεύτερη επέμβαση όπου αντικαθίσταται ο διατατήρας με το μόνιμο ένθεμα ενώ
           συγχρόνως γίνεται διόρθωση του ετερόπλευρου μαστού με πηξία, αύξηση ή μείωση, για την επίτευξη όσο το δυνατόν καλύτερης συμμετρίας, εάν και εφόσον χρειάζεται.
        </p>
        <p>
           Τα πλεονεκτήματα της μεθόδου αυτής είναι ότι η βαρύτητα των επεμβάσεων είναι μικρότερη, δεν δημιουργείται άλλο χειρουργικό τραύμα και δευτερογενές έλλειμμα και η αποθεραπεία
           είναι συντομότερη. Στα μειονεκτήματα συγκαταλέγονται, ο δυνητικός κίνδυνος λοίμωξης μέχρι και έκθεσης του «ξένου» σώματος όπως και η δημιουργία της ινώδους κάψας, που μπορεί
           να οδηγήσει σε παραμορφώσεις, σε ποσοστό 3%.
        </p>
        <h3>Αποκατάσταση με αυτόλογους ιστούς</h3>
        <p>
           Η χρήση αυτόλογων ιστών έχει προσδώσει τα τελευταία χρόνια σημαντικές αλλαγές στην αποκατάσταση μαστού, ιδιαίτερα όταν το δέρμα της περιοχής της μαστεκτομής έχει αλλοιώσεις από
           την ακτινοθεραπεία. Η χρήση μυοδερματικών κρημνών μισχωτών ή ελεύθερων αλλά και δερμοϋποδόριων βασιζόμενων σε διατιτρώσες βαθύτερων αγγείων ( perforator flaps ) έδωσαν τη
           δυνατότητα μεταφοράς μαλακών ιστών στην περιοχή της μαστεκτομής. Από τους πιο συχνά χρησιμοποιούμενους μυοδερματικούς κρημνούς είναι αυτός που λαμβάνεται από το κάτω μέρος της
           κοιλιάς με μίσχο τον ορθό κοιλιακό μυ. Ο κρημνός αυτός που ονομάζεται TRAM ( Transverse Rectus Abdominis Myocutaneous)  προσφέρει ικανοποιητική ποσότητα ιστού για την ανάπλαση
           του μαστού, μπορεί να μιμηθεί τον ετερόπλευρο μαστό σε διαστάσεις ώστε να μην χρειάζεται επέμβαση στον άλλο μαστό για την διόρθωση της συμμετρίας, ενώ καταλείπει οριζόντια ουλή
           στη δότρια περιοχή, ως επί της επέμβασης της κοιλιοπλαστικής. Στα μειονεκτήματα της μεθόδου είναι η μεγαλύτερη βαρύτητα της επέμβασης, ο κίνδυνος νέκρωσης μέρους ή και ολόκληρου
           του κρημνού και η πιθανότητα εμφάνισης κήλης στο κοιλιακό τοίχωμα λόγω εξασθένισης του. Η νεότερη εξέλιξη χρήσης των δερμοϋποδόριων κρημνών από την περιοχή της κοιλιάς των
           βασιζόμενων σε διατιτρώσες ( DIEP flaps ) που αφήνουν άθικτο τον ορθό κοιλιακό μυ, μηδενίζουν την πιθανότητα εμφάνισης μετεγχειρητικής κήλης, ενέχουν όμως τον κίνδυνο απώλειας
           του κρημνού λόγω θρόμβωσης της αναστόμωσης των αγγείων κατά την ελεύθερη μεταφορά τους. Εναλλακτική λύση στη μεταφορά αυτόλογων ιστών είναι η μεταφορά του πλατέως ραχιαίου μυός
           με δερματική νησίδα, που λόγω του μικρού του όγκου απαιτεί και την ταυτόχρονη χρήση ενθέματος σιλικόνης.
        </p>
        <p>
          Είτε επιλεγεί η χρήση ενθεμάτων ή η μεταμόσχευση αυτόλογων ιστών, η αποκατάσταση ολοκληρώνεται ταυτόχρονα με τη δεύτερη φάση ή μεμονωμένα σε τρίτη φάση με τη δημιουργία νέο
          συμπλέγματος θηλής - άλω στον αναπλασμένο μαστό. Η χρήση τοπικών δερματικών κρημνών αποκαθιστά την θηλή και η δημιουργία της άλω γίνεται με τη λήψη ελεύθερου δερματικού
          μοσχεύματος από τη μηροβουβωνική περιοχή ή με την εφαρμογή τατουάζ.
        </p>
        <p>
          Σήμερα με την ραγδαία εξέλιξη της πλαστικής χειρουργικής η επιλογή χρήσης ενθεμάτων ή αυτόλογων ιστών εξατομικεύεται, βασιζόμενη σε αντικειμενικές όσο το δυνατόν παραμέτρους,
          εξακολουθεί όμως να παίζει σημαίνοντα ρόλο και η επιλογή της ασθενούς, μέσα από τις εναλλακτικές μεθόδους που προτείνονται από τον ειδικό. Σε καμία όμως περίπτωση η όποια μέθοδος
          αποκατάστασης τελικά επιλέγεται δεν θα πρέπει να αντιβαίνει τις ογκολογικές παραμέτρους της υποκείμενης νόσου της ασθενούς.
        </p>
        <h4>Σχετικά με την επέμβαση</h4>
        <p>
          Η επέμβαση γίνεται με γενική αναισθησία σε οργανωμένα κέντρα και η διάρκεια παραμονής στο νοσοκομείο είναι από μία έως δύο ημέρες. Τοποθετούνται παροχετεύσεις σε όλες τις
          περιπτώσεις αποκατάστασης με αυτόλογους ιστούς και μερικές φορές όταν χρησιμοποιούμε ενθέματα, που αφαιρούνται όταν μειωθεί η συλλογή των υγρών μετά από μερικές μέρες.
        </p>
        <h4>Σχετικά με τη μετεγχειρητική περίοδο</h4>
        <p>
          Σε περίπτωση τοποθέτησης διατατήρα ιστών, η διάταση γίνεται σταδιακά στο ιατρείο ανά 7-10 μέρες για τον πρώτο μήνα, οπότε και ολοκληρώνεται.
        </p>
        <p>
          Στην περίπτωση χρήσης αυτόλογων ιστών η περίοδος ανάρρωσης διαρκεί περισσότερο σε σχέση με τα ενθέματα αφού θεωρείται μεγαλύτερης βαρύτητας επέμβαση, τα αποτελέσματα όμως
          κρίνονται πιο «φυσικά».
        </p>
        <p>
          Η επιστροφή στις φυσιολογικές δραστηριότητες γίνεται μετά τον ένα έως και ενάμισι μήνα από το χειρουργείο, συμπεριλαμβανομένης και της πιθανής άθλησης.
        </p>
        <p>
          Για όλο αυτό το διάστημα χρειάζεται η εφαρμογή ειδικού τύπου στηθόδεσμος καθώς και η εφαρμογή πιεστικής ζώνης στην κοιλιά, εάν χρησιμοποιηθεί ο κοιλιακός κρημνός για
          την αποκατάσταση.
        </p>
        <p>
          Όλες αυτές οι επεμβάσεις αποκατάστασης του μαστού  θεωρούνται από μεσαίας έως και μεγάλης βαρύτητας ανάλογα με τη μέθοδο που θα επιλεγεί και ο κίνδυνος των επιπλοκών που μπορεί
          να συμβούν τόσο κατά την άμεση όσο και την απώτερη μετεγχειρητική περίοδο, θα πρέπει να συζητηθούν εκτενώς με τον χειρουργό.
        </p>
        <br/><br/>
        <FontAwesome className='scrollArrowBack' onClick={executeScroll} name='angle-up' data-hover='πίσω στο μενού' spin={false} size='2x' />
        <br/><br/>
      </div>
)};

export default Repairmastos
