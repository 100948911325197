import React from 'react';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return null
  }
}


const Notice = () => (
  <div className="sub_page">
    <ScrollToTopOnMount/>
    <h1> ΔΗΛΩΣΗ – ΣΥΝAIΝEΣΗ ΠΕΡΙ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ </h1>
    <p>
      Σύμφωνα με τον νέο ευρωπαϊκό κανονισμό για την προστασία των δεδομένων προσωπικού χαρακτήρα( GDPR ) που ισχύει από την 25η Μαΐου 2018, θα χρειαστούμε τη συγκατάθεσή σας,
      ώστε <b>να μπορούμε να σας ενημερώνουμε για τις δράσεις και νέες εφαρμογές του Κέντρου μας</b>.
    </p>
    <p>
      Δεσμευόμαστε ότι τα στοιχεία σας φυλάσσονται ασφαλή και κρυπτογραφημένα και εξαιρούνται από κάθε άλλη χρήση πλην της αποστολής ενημερωτικών μηνυμάτων με ηλεκτρονικό
      ταχυδρομείο, επιστολική αποστολή, χρήση SMS ή τηλεφωνικής επαφής. Είναι τα ελάχιστα στοιχεία που απαιτούνται προκειμένου να υπάρχει μια ποιοτική επικοινωνία, για
      ζητήματα που σας ενδιαφέρουν.
    </p>
    <p>
      Με την παρούσα αποστολή ερώτησης, συναινείτε στη διατήρηση των προσωπικών σας δεδομένων για την ανωτέρω χρήση από την εταιρεία <b>ACE ATHENS</b>.
    </p>

  </div>
)

export default Notice
