import React from 'react';
import soon from '../images/coming_soon_aceathens.jpg'


const Trauma = () => (
  <div className="sub_page">
  <h1> ΤΡΑΥΜΑ & ΕΛΛΕΙΜΑΤΑ ΜΑΛΑΚΩΝ ΜΟΡΙΩΝ </h1>
    <div className="sub_flex">
      <div className="flexitem_a">
        <img src={soon} alt="" className="flexitem_text_w_picture_wide" />
     </div>
    </div>
  </div>
)

export default Trauma
